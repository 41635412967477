{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-07-15",
    "endpointPrefix": "media-pipelines-chime",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Chime SDK Media Pipelines",
    "serviceId": "Chime SDK Media Pipelines",
    "signatureVersion": "v4",
    "signingName": "chime",
    "uid": "chime-sdk-media-pipelines-2021-07-15"
  },
  "operations": {
    "CreateMediaCapturePipeline": {
      "http": {
        "requestUri": "/sdk-media-capture-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceType",
          "SourceArn",
          "SinkType",
          "SinkArn"
        ],
        "members": {
          "SourceType": {},
          "SourceArn": {
            "shape": "S3"
          },
          "SinkType": {},
          "SinkArn": {
            "shape": "S3"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          },
          "ChimeSdkMeetingConfiguration": {
            "shape": "S6"
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaCapturePipeline": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateMediaConcatenationPipeline": {
      "http": {
        "requestUri": "/sdk-media-concatenation-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Sources",
          "Sinks"
        ],
        "members": {
          "Sources": {
            "shape": "S12"
          },
          "Sinks": {
            "shape": "S1h"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaConcatenationPipeline": {
            "shape": "S1m"
          }
        }
      }
    },
    "CreateMediaInsightsPipeline": {
      "http": {
        "requestUri": "/media-insights-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaInsightsPipelineConfigurationArn"
        ],
        "members": {
          "MediaInsightsPipelineConfigurationArn": {
            "shape": "S3"
          },
          "KinesisVideoStreamSourceRuntimeConfiguration": {
            "shape": "S1o"
          },
          "MediaInsightsRuntimeMetadata": {
            "shape": "S21"
          },
          "KinesisVideoStreamRecordingSourceRuntimeConfiguration": {
            "shape": "S24"
          },
          "S3RecordingSinkRuntimeConfiguration": {
            "shape": "S2b"
          },
          "Tags": {
            "shape": "Ss"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MediaInsightsPipeline"
        ],
        "members": {
          "MediaInsightsPipeline": {
            "shape": "S2e"
          }
        }
      }
    },
    "CreateMediaInsightsPipelineConfiguration": {
      "http": {
        "requestUri": "/media-insights-pipeline-configurations",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaInsightsPipelineConfigurationName",
          "ResourceAccessRoleArn",
          "Elements"
        ],
        "members": {
          "MediaInsightsPipelineConfigurationName": {},
          "ResourceAccessRoleArn": {
            "shape": "S3"
          },
          "RealTimeAlertConfiguration": {
            "shape": "S2h"
          },
          "Elements": {
            "shape": "S2u"
          },
          "Tags": {
            "shape": "Ss"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaInsightsPipelineConfiguration": {
            "shape": "S3j"
          }
        }
      }
    },
    "CreateMediaLiveConnectorPipeline": {
      "http": {
        "requestUri": "/sdk-media-live-connector-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Sources",
          "Sinks"
        ],
        "members": {
          "Sources": {
            "shape": "S3l"
          },
          "Sinks": {
            "shape": "S3q"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaLiveConnectorPipeline": {
            "shape": "S3y"
          }
        }
      }
    },
    "DeleteMediaCapturePipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sdk-media-capture-pipelines/{mediaPipelineId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      }
    },
    "DeleteMediaInsightsPipelineConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/media-insights-pipeline-configurations/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      }
    },
    "DeleteMediaPipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sdk-media-pipelines/{mediaPipelineId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      }
    },
    "GetMediaCapturePipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-capture-pipelines/{mediaPipelineId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaCapturePipeline": {
            "shape": "Sx"
          }
        }
      }
    },
    "GetMediaInsightsPipelineConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/media-insights-pipeline-configurations/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaInsightsPipelineConfiguration": {
            "shape": "S3j"
          }
        }
      }
    },
    "GetMediaPipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-pipelines/{mediaPipelineId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaPipeline": {
            "type": "structure",
            "members": {
              "MediaCapturePipeline": {
                "shape": "Sx"
              },
              "MediaLiveConnectorPipeline": {
                "shape": "S3y"
              },
              "MediaConcatenationPipeline": {
                "shape": "S1m"
              },
              "MediaInsightsPipeline": {
                "shape": "S2e"
              }
            }
          }
        }
      }
    },
    "ListMediaCapturePipelines": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-capture-pipelines",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaCapturePipelines": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MediaPipelineId": {},
                "MediaPipelineArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMediaInsightsPipelineConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/media-insights-pipeline-configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaInsightsPipelineConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MediaInsightsPipelineConfigurationName": {},
                "MediaInsightsPipelineConfigurationId": {},
                "MediaInsightsPipelineConfigurationArn": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMediaPipelines": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-pipelines",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaPipelines": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MediaPipelineId": {},
                "MediaPipelineArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Ss"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags?operation=tag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/tags?operation=untag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateMediaInsightsPipelineConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/media-insights-pipeline-configurations/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier",
          "ResourceAccessRoleArn",
          "Elements"
        ],
        "members": {
          "Identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "ResourceAccessRoleArn": {
            "shape": "S3"
          },
          "RealTimeAlertConfiguration": {
            "shape": "S2h"
          },
          "Elements": {
            "shape": "S2u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaInsightsPipelineConfiguration": {
            "shape": "S3j"
          }
        }
      }
    },
    "UpdateMediaInsightsPipelineStatus": {
      "http": {
        "method": "PUT",
        "requestUri": "/media-insights-pipeline-status/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier",
          "UpdateStatus"
        ],
        "members": {
          "Identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "UpdateStatus": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S5": {
      "type": "string",
      "sensitive": true
    },
    "S6": {
      "type": "structure",
      "members": {
        "SourceConfiguration": {
          "shape": "S7"
        },
        "ArtifactsConfiguration": {
          "type": "structure",
          "required": [
            "Audio",
            "Video",
            "Content"
          ],
          "members": {
            "Audio": {
              "type": "structure",
              "required": [
                "MuxType"
              ],
              "members": {
                "MuxType": {}
              }
            },
            "Video": {
              "type": "structure",
              "required": [
                "State"
              ],
              "members": {
                "State": {},
                "MuxType": {}
              }
            },
            "Content": {
              "type": "structure",
              "required": [
                "State"
              ],
              "members": {
                "State": {},
                "MuxType": {}
              }
            },
            "CompositedVideo": {
              "shape": "Sl"
            }
          }
        }
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "SelectedVideoStreams": {
          "type": "structure",
          "members": {
            "AttendeeIds": {
              "type": "list",
              "member": {}
            },
            "ExternalUserIds": {
              "type": "list",
              "member": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "GridViewConfiguration"
      ],
      "members": {
        "Layout": {},
        "Resolution": {},
        "GridViewConfiguration": {
          "type": "structure",
          "required": [
            "ContentShareLayout"
          ],
          "members": {
            "ContentShareLayout": {},
            "PresenterOnlyConfiguration": {
              "type": "structure",
              "members": {
                "PresenterPosition": {}
              }
            }
          }
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "MediaPipelineId": {},
        "MediaPipelineArn": {},
        "SourceType": {},
        "SourceArn": {
          "shape": "S3"
        },
        "Status": {},
        "SinkType": {},
        "SinkArn": {
          "shape": "S3"
        },
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        },
        "ChimeSdkMeetingConfiguration": {
          "shape": "S6"
        }
      }
    },
    "S10": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type",
          "MediaCapturePipelineSourceConfiguration"
        ],
        "members": {
          "Type": {},
          "MediaCapturePipelineSourceConfiguration": {
            "type": "structure",
            "required": [
              "MediaPipelineArn",
              "ChimeSdkMeetingConfiguration"
            ],
            "members": {
              "MediaPipelineArn": {
                "shape": "S3"
              },
              "ChimeSdkMeetingConfiguration": {
                "type": "structure",
                "required": [
                  "ArtifactsConfiguration"
                ],
                "members": {
                  "ArtifactsConfiguration": {
                    "type": "structure",
                    "required": [
                      "Audio",
                      "Video",
                      "Content",
                      "DataChannel",
                      "TranscriptionMessages",
                      "MeetingEvents",
                      "CompositedVideo"
                    ],
                    "members": {
                      "Audio": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "Video": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "Content": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "DataChannel": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "TranscriptionMessages": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "MeetingEvents": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "CompositedVideo": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type",
          "S3BucketSinkConfiguration"
        ],
        "members": {
          "Type": {},
          "S3BucketSinkConfiguration": {
            "type": "structure",
            "required": [
              "Destination"
            ],
            "members": {
              "Destination": {
                "shape": "S3"
              }
            }
          }
        }
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "MediaPipelineId": {},
        "MediaPipelineArn": {},
        "Sources": {
          "shape": "S12"
        },
        "Sinks": {
          "shape": "S1h"
        },
        "Status": {},
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        }
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "Streams",
        "MediaEncoding",
        "MediaSampleRate"
      ],
      "members": {
        "Streams": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "StreamArn",
              "StreamChannelDefinition"
            ],
            "members": {
              "StreamArn": {},
              "FragmentNumber": {},
              "StreamChannelDefinition": {
                "type": "structure",
                "required": [
                  "NumberOfChannels"
                ],
                "members": {
                  "NumberOfChannels": {
                    "type": "integer"
                  },
                  "ChannelDefinitions": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "ChannelId"
                      ],
                      "members": {
                        "ChannelId": {
                          "type": "integer"
                        },
                        "ParticipantRole": {}
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "MediaEncoding": {},
        "MediaSampleRate": {
          "type": "integer"
        }
      }
    },
    "S21": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "S24": {
      "type": "structure",
      "required": [
        "Streams",
        "FragmentSelector"
      ],
      "members": {
        "Streams": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "StreamArn": {}
            }
          }
        },
        "FragmentSelector": {
          "type": "structure",
          "required": [
            "FragmentSelectorType",
            "TimestampRange"
          ],
          "members": {
            "FragmentSelectorType": {},
            "TimestampRange": {
              "type": "structure",
              "required": [
                "StartTimestamp",
                "EndTimestamp"
              ],
              "members": {
                "StartTimestamp": {
                  "type": "timestamp"
                },
                "EndTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "S2b": {
      "type": "structure",
      "required": [
        "Destination",
        "RecordingFileFormat"
      ],
      "members": {
        "Destination": {
          "shape": "S3"
        },
        "RecordingFileFormat": {}
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "MediaPipelineId": {},
        "MediaPipelineArn": {
          "shape": "S3"
        },
        "MediaInsightsPipelineConfigurationArn": {
          "shape": "S3"
        },
        "Status": {},
        "KinesisVideoStreamSourceRuntimeConfiguration": {
          "shape": "S1o"
        },
        "MediaInsightsRuntimeMetadata": {
          "shape": "S21"
        },
        "KinesisVideoStreamRecordingSourceRuntimeConfiguration": {
          "shape": "S24"
        },
        "S3RecordingSinkRuntimeConfiguration": {
          "shape": "S2b"
        },
        "CreatedTimestamp": {
          "shape": "S10"
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "Disabled": {
          "type": "boolean"
        },
        "Rules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "KeywordMatchConfiguration": {
                "type": "structure",
                "required": [
                  "RuleName",
                  "Keywords"
                ],
                "members": {
                  "RuleName": {},
                  "Keywords": {
                    "type": "list",
                    "member": {}
                  },
                  "Negate": {
                    "type": "boolean"
                  }
                }
              },
              "SentimentConfiguration": {
                "type": "structure",
                "required": [
                  "RuleName",
                  "SentimentType",
                  "TimePeriod"
                ],
                "members": {
                  "RuleName": {},
                  "SentimentType": {},
                  "TimePeriod": {
                    "type": "integer"
                  }
                }
              },
              "IssueDetectionConfiguration": {
                "type": "structure",
                "required": [
                  "RuleName"
                ],
                "members": {
                  "RuleName": {}
                }
              }
            }
          }
        }
      }
    },
    "S2u": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "Type": {},
          "AmazonTranscribeCallAnalyticsProcessorConfiguration": {
            "type": "structure",
            "required": [
              "LanguageCode"
            ],
            "members": {
              "LanguageCode": {},
              "VocabularyName": {},
              "VocabularyFilterName": {},
              "VocabularyFilterMethod": {},
              "LanguageModelName": {},
              "EnablePartialResultsStabilization": {
                "type": "boolean"
              },
              "PartialResultsStability": {},
              "ContentIdentificationType": {},
              "ContentRedactionType": {},
              "PiiEntityTypes": {},
              "FilterPartialResults": {
                "type": "boolean"
              },
              "PostCallAnalyticsSettings": {
                "type": "structure",
                "required": [
                  "OutputLocation",
                  "DataAccessRoleArn"
                ],
                "members": {
                  "OutputLocation": {},
                  "DataAccessRoleArn": {},
                  "ContentRedactionOutput": {},
                  "OutputEncryptionKMSKeyId": {}
                }
              },
              "CallAnalyticsStreamCategories": {
                "type": "list",
                "member": {}
              }
            }
          },
          "AmazonTranscribeProcessorConfiguration": {
            "type": "structure",
            "required": [
              "LanguageCode"
            ],
            "members": {
              "LanguageCode": {},
              "VocabularyName": {},
              "VocabularyFilterName": {},
              "VocabularyFilterMethod": {},
              "ShowSpeakerLabel": {
                "type": "boolean"
              },
              "EnablePartialResultsStabilization": {
                "type": "boolean"
              },
              "PartialResultsStability": {},
              "ContentIdentificationType": {},
              "ContentRedactionType": {},
              "PiiEntityTypes": {},
              "LanguageModelName": {},
              "FilterPartialResults": {
                "type": "boolean"
              }
            }
          },
          "KinesisDataStreamSinkConfiguration": {
            "type": "structure",
            "members": {
              "InsightsTarget": {
                "shape": "S3"
              }
            }
          },
          "S3RecordingSinkConfiguration": {
            "type": "structure",
            "members": {
              "Destination": {
                "shape": "S3"
              },
              "RecordingFileFormat": {}
            }
          },
          "VoiceAnalyticsProcessorConfiguration": {
            "type": "structure",
            "members": {
              "SpeakerSearchStatus": {},
              "VoiceToneAnalysisStatus": {}
            }
          },
          "LambdaFunctionSinkConfiguration": {
            "type": "structure",
            "members": {
              "InsightsTarget": {
                "shape": "S3"
              }
            }
          },
          "SqsQueueSinkConfiguration": {
            "type": "structure",
            "members": {
              "InsightsTarget": {
                "shape": "S3"
              }
            }
          },
          "SnsTopicSinkConfiguration": {
            "type": "structure",
            "members": {
              "InsightsTarget": {
                "shape": "S3"
              }
            }
          }
        }
      }
    },
    "S3j": {
      "type": "structure",
      "members": {
        "MediaInsightsPipelineConfigurationName": {},
        "MediaInsightsPipelineConfigurationArn": {
          "shape": "S3"
        },
        "ResourceAccessRoleArn": {
          "shape": "S3"
        },
        "RealTimeAlertConfiguration": {
          "shape": "S2h"
        },
        "Elements": {
          "shape": "S2u"
        },
        "MediaInsightsPipelineConfigurationId": {},
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        }
      }
    },
    "S3l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SourceType",
          "ChimeSdkMeetingLiveConnectorConfiguration"
        ],
        "members": {
          "SourceType": {},
          "ChimeSdkMeetingLiveConnectorConfiguration": {
            "type": "structure",
            "required": [
              "Arn",
              "MuxType"
            ],
            "members": {
              "Arn": {
                "shape": "S3"
              },
              "MuxType": {},
              "CompositedVideo": {
                "shape": "Sl"
              },
              "SourceConfiguration": {
                "shape": "S7"
              }
            }
          }
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SinkType",
          "RTMPConfiguration"
        ],
        "members": {
          "SinkType": {},
          "RTMPConfiguration": {
            "type": "structure",
            "required": [
              "Url"
            ],
            "members": {
              "Url": {
                "type": "string",
                "sensitive": true
              },
              "AudioChannels": {},
              "AudioSampleRate": {}
            }
          }
        }
      }
    },
    "S3y": {
      "type": "structure",
      "members": {
        "Sources": {
          "shape": "S3l"
        },
        "Sinks": {
          "shape": "S3q"
        },
        "MediaPipelineId": {},
        "MediaPipelineArn": {},
        "Status": {},
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        }
      }
    }
  }
}