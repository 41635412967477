{
  "pagination": {
    "ListAnnotationImportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "annotationImportJobs"
    },
    "ListAnnotationStores": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "annotationStores"
    },
    "ListMultipartReadSetUploads": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "uploads"
    },
    "ListReadSetActivationJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "activationJobs"
    },
    "ListReadSetExportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "exportJobs"
    },
    "ListReadSetImportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "importJobs"
    },
    "ListReadSetUploadParts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "parts"
    },
    "ListReadSets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "readSets"
    },
    "ListReferenceImportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "importJobs"
    },
    "ListReferenceStores": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "referenceStores"
    },
    "ListReferences": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "references"
    },
    "ListRunGroups": {
      "input_token": "startingToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListRunTasks": {
      "input_token": "startingToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListRuns": {
      "input_token": "startingToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListSequenceStores": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "sequenceStores"
    },
    "ListVariantImportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "variantImportJobs"
    },
    "ListVariantStores": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "variantStores"
    },
    "ListWorkflows": {
      "input_token": "startingToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    }
  }
}
