{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-04-30",
    "endpointPrefix": "resiliencehub",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Resilience Hub",
    "serviceId": "resiliencehub",
    "signatureVersion": "v4",
    "signingName": "resiliencehub",
    "uid": "resiliencehub-2020-04-30"
  },
  "operations": {
    "AddDraftAppVersionResourceMappings": {
      "http": {
        "requestUri": "/add-draft-app-version-resource-mappings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "resourceMappings"
        ],
        "members": {
          "appArn": {},
          "resourceMappings": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "resourceMappings"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "resourceMappings": {
            "shape": "S3"
          }
        }
      }
    },
    "CreateApp": {
      "http": {
        "requestUri": "/create-app",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "assessmentSchedule": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "name": {},
          "policyArn": {},
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "app"
        ],
        "members": {
          "app": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateAppVersionAppComponent": {
      "http": {
        "requestUri": "/create-app-version-app-component",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "name",
          "type"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "id": {},
          "name": {},
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appComponent": {
            "shape": "Sx"
          },
          "appVersion": {}
        }
      }
    },
    "CreateAppVersionResource": {
      "http": {
        "requestUri": "/create-app-version-resource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appComponents",
          "logicalResourceId",
          "physicalResourceId",
          "resourceType"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {},
          "appComponents": {
            "shape": "Sz"
          },
          "awsAccountId": {},
          "awsRegion": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "logicalResourceId": {
            "shape": "S10"
          },
          "physicalResourceId": {},
          "resourceName": {},
          "resourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "physicalResource": {
            "shape": "S13"
          }
        }
      }
    },
    "CreateRecommendationTemplate": {
      "http": {
        "requestUri": "/create-recommendation-template",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn",
          "name"
        ],
        "members": {
          "assessmentArn": {},
          "bucketName": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "format": {},
          "name": {},
          "recommendationIds": {
            "shape": "S19"
          },
          "recommendationTypes": {
            "shape": "S1b"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommendationTemplate": {
            "shape": "S1e"
          }
        }
      }
    },
    "CreateResiliencyPolicy": {
      "http": {
        "requestUri": "/create-resiliency-policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "policy",
          "policyName",
          "tier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "dataLocationConstraint": {},
          "policy": {
            "shape": "S1k"
          },
          "policyDescription": {},
          "policyName": {},
          "tags": {
            "shape": "Si"
          },
          "tier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policy"
        ],
        "members": {
          "policy": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteApp": {
      "http": {
        "requestUri": "/delete-app",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "forceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {}
        }
      }
    },
    "DeleteAppAssessment": {
      "http": {
        "requestUri": "/delete-app-assessment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentArn",
          "assessmentStatus"
        ],
        "members": {
          "assessmentArn": {},
          "assessmentStatus": {}
        }
      }
    },
    "DeleteAppInputSource": {
      "http": {
        "requestUri": "/delete-app-input-source",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "eksSourceClusterNamespace": {
            "shape": "S1y"
          },
          "sourceArn": {},
          "terraformSource": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "appArn": {},
          "appInputSource": {
            "shape": "S23"
          }
        }
      }
    },
    "DeleteAppVersionAppComponent": {
      "http": {
        "requestUri": "/delete-app-version-app-component",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "id"
        ],
        "members": {
          "appArn": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appComponent": {
            "shape": "Sx"
          },
          "appVersion": {}
        }
      }
    },
    "DeleteAppVersionResource": {
      "http": {
        "requestUri": "/delete-app-version-resource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "awsAccountId": {},
          "awsRegion": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "logicalResourceId": {
            "shape": "S10"
          },
          "physicalResourceId": {},
          "resourceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "physicalResource": {
            "shape": "S13"
          }
        }
      }
    },
    "DeleteRecommendationTemplate": {
      "http": {
        "requestUri": "/delete-recommendation-template",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recommendationTemplateArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "recommendationTemplateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "recommendationTemplateArn",
          "status"
        ],
        "members": {
          "recommendationTemplateArn": {},
          "status": {}
        }
      }
    },
    "DeleteResiliencyPolicy": {
      "http": {
        "requestUri": "/delete-resiliency-policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "policyArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyArn"
        ],
        "members": {
          "policyArn": {}
        }
      }
    },
    "DescribeApp": {
      "http": {
        "requestUri": "/describe-app",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "app"
        ],
        "members": {
          "app": {
            "shape": "Sm"
          }
        }
      }
    },
    "DescribeAppAssessment": {
      "http": {
        "requestUri": "/describe-app-assessment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessment"
        ],
        "members": {
          "assessment": {
            "shape": "S2h"
          }
        }
      }
    },
    "DescribeAppVersion": {
      "http": {
        "requestUri": "/describe-app-version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {},
          "appVersion": {}
        }
      }
    },
    "DescribeAppVersionAppComponent": {
      "http": {
        "requestUri": "/describe-app-version-app-component",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "id"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appComponent": {
            "shape": "Sx"
          },
          "appVersion": {}
        }
      }
    },
    "DescribeAppVersionResource": {
      "http": {
        "requestUri": "/describe-app-version-resource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "awsAccountId": {},
          "awsRegion": {},
          "logicalResourceId": {
            "shape": "S10"
          },
          "physicalResourceId": {},
          "resourceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "physicalResource": {
            "shape": "S13"
          }
        }
      }
    },
    "DescribeAppVersionResourcesResolutionStatus": {
      "http": {
        "requestUri": "/describe-app-version-resources-resolution-status",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "resolutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "resolutionId",
          "status"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "errorMessage": {},
          "resolutionId": {},
          "status": {}
        }
      }
    },
    "DescribeAppVersionTemplate": {
      "http": {
        "requestUri": "/describe-app-version-template",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appTemplateBody",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appTemplateBody": {},
          "appVersion": {}
        }
      }
    },
    "DescribeDraftAppVersionResourcesImportStatus": {
      "http": {
        "requestUri": "/describe-draft-app-version-resources-import-status",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "status",
          "statusChangeTime"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "errorMessage": {},
          "status": {},
          "statusChangeTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeResiliencyPolicy": {
      "http": {
        "requestUri": "/describe-resiliency-policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "policyArn"
        ],
        "members": {
          "policyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policy"
        ],
        "members": {
          "policy": {
            "shape": "S1q"
          }
        }
      }
    },
    "ImportResourcesToDraftAppVersion": {
      "http": {
        "requestUri": "/import-resources-to-draft-app-version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "eksSources": {
            "shape": "S3d"
          },
          "importStrategy": {},
          "sourceArns": {
            "shape": "S3h"
          },
          "terraformSources": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "status"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "eksSources": {
            "shape": "S3d"
          },
          "sourceArns": {
            "shape": "S3h"
          },
          "status": {},
          "terraformSources": {
            "shape": "S3i"
          }
        }
      }
    },
    "ListAlarmRecommendations": {
      "http": {
        "requestUri": "/list-alarm-recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "alarmRecommendations"
        ],
        "members": {
          "alarmRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "recommendationId",
                "referenceId",
                "type"
              ],
              "members": {
                "appComponentName": {},
                "description": {},
                "items": {
                  "shape": "S3r"
                },
                "name": {},
                "prerequisite": {},
                "recommendationId": {},
                "referenceId": {},
                "type": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAppAssessments": {
      "http": {
        "method": "GET",
        "requestUri": "/list-app-assessments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "appArn": {
            "location": "querystring",
            "locationName": "appArn"
          },
          "assessmentName": {
            "location": "querystring",
            "locationName": "assessmentName"
          },
          "assessmentStatus": {
            "location": "querystring",
            "locationName": "assessmentStatus",
            "type": "list",
            "member": {}
          },
          "complianceStatus": {
            "location": "querystring",
            "locationName": "complianceStatus"
          },
          "invoker": {
            "location": "querystring",
            "locationName": "invoker"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "reverseOrder": {
            "location": "querystring",
            "locationName": "reverseOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentSummaries"
        ],
        "members": {
          "assessmentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "assessmentArn",
                "assessmentStatus"
              ],
              "members": {
                "appArn": {},
                "appVersion": {},
                "assessmentArn": {},
                "assessmentName": {},
                "assessmentStatus": {},
                "complianceStatus": {},
                "cost": {
                  "shape": "S2l"
                },
                "endTime": {
                  "type": "timestamp"
                },
                "invoker": {},
                "message": {},
                "resiliencyScore": {
                  "type": "double"
                },
                "startTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAppComponentCompliances": {
      "http": {
        "requestUri": "/list-app-component-compliances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "componentCompliances"
        ],
        "members": {
          "componentCompliances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "appComponentName": {},
                "compliance": {
                  "shape": "S2i"
                },
                "cost": {
                  "shape": "S2l"
                },
                "message": {},
                "resiliencyScore": {
                  "shape": "S2p"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAppComponentRecommendations": {
      "http": {
        "requestUri": "/list-app-component-recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "componentRecommendations"
        ],
        "members": {
          "componentRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appComponentName",
                "configRecommendations",
                "recommendationStatus"
              ],
              "members": {
                "appComponentName": {},
                "configRecommendations": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "name",
                      "optimizationType",
                      "referenceId"
                    ],
                    "members": {
                      "appComponentName": {},
                      "compliance": {
                        "shape": "S2i"
                      },
                      "cost": {
                        "shape": "S2l"
                      },
                      "description": {},
                      "haArchitecture": {},
                      "name": {},
                      "optimizationType": {},
                      "recommendationCompliance": {
                        "type": "map",
                        "key": {},
                        "value": {
                          "type": "structure",
                          "required": [
                            "expectedComplianceStatus"
                          ],
                          "members": {
                            "expectedComplianceStatus": {},
                            "expectedRpoDescription": {},
                            "expectedRpoInSecs": {
                              "type": "integer"
                            },
                            "expectedRtoDescription": {},
                            "expectedRtoInSecs": {
                              "type": "integer"
                            }
                          }
                        }
                      },
                      "referenceId": {},
                      "suggestedChanges": {
                        "type": "list",
                        "member": {}
                      }
                    }
                  }
                },
                "recommendationStatus": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAppInputSources": {
      "http": {
        "requestUri": "/list-app-input-sources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appInputSources"
        ],
        "members": {
          "appInputSources": {
            "type": "list",
            "member": {
              "shape": "S23"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAppVersionAppComponents": {
      "http": {
        "requestUri": "/list-app-version-app-components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appComponents": {
            "shape": "S14"
          },
          "appVersion": {},
          "nextToken": {}
        }
      }
    },
    "ListAppVersionResourceMappings": {
      "http": {
        "requestUri": "/list-app-version-resource-mappings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resourceMappings"
        ],
        "members": {
          "nextToken": {},
          "resourceMappings": {
            "shape": "S3"
          }
        }
      }
    },
    "ListAppVersionResources": {
      "http": {
        "requestUri": "/list-app-version-resources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "resolutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "physicalResources",
          "resolutionId"
        ],
        "members": {
          "nextToken": {},
          "physicalResources": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "resolutionId": {}
        }
      }
    },
    "ListAppVersions": {
      "http": {
        "requestUri": "/list-app-versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appVersions"
        ],
        "members": {
          "appVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appVersion"
              ],
              "members": {
                "appVersion": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListApps": {
      "http": {
        "method": "GET",
        "requestUri": "/list-apps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "appArn": {
            "location": "querystring",
            "locationName": "appArn"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appSummaries"
        ],
        "members": {
          "appSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appArn",
                "creationTime",
                "name"
              ],
              "members": {
                "appArn": {},
                "assessmentSchedule": {},
                "complianceStatus": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "description": {},
                "name": {},
                "resiliencyScore": {
                  "type": "double"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRecommendationTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/list-recommendation-templates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {
            "location": "querystring",
            "locationName": "assessmentArn"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "recommendationTemplateArn": {
            "location": "querystring",
            "locationName": "recommendationTemplateArn"
          },
          "reverseOrder": {
            "location": "querystring",
            "locationName": "reverseOrder",
            "type": "boolean"
          },
          "status": {
            "location": "querystring",
            "locationName": "status",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "recommendationTemplates": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          }
        }
      }
    },
    "ListResiliencyPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/list-resiliency-policies",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "policyName": {
            "location": "querystring",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resiliencyPolicies"
        ],
        "members": {
          "nextToken": {},
          "resiliencyPolicies": {
            "shape": "S54"
          }
        }
      }
    },
    "ListSopRecommendations": {
      "http": {
        "requestUri": "/list-sop-recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "sopRecommendations"
        ],
        "members": {
          "nextToken": {},
          "sopRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "recommendationId",
                "referenceId",
                "serviceType"
              ],
              "members": {
                "appComponentName": {},
                "description": {},
                "items": {
                  "shape": "S3r"
                },
                "name": {},
                "prerequisite": {},
                "recommendationId": {},
                "referenceId": {},
                "serviceType": {}
              }
            }
          }
        }
      }
    },
    "ListSuggestedResiliencyPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/list-suggested-resiliency-policies",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resiliencyPolicies"
        ],
        "members": {
          "nextToken": {},
          "resiliencyPolicies": {
            "shape": "S54"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Si"
          }
        }
      }
    },
    "ListTestRecommendations": {
      "http": {
        "requestUri": "/list-test-recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assessmentArn"
        ],
        "members": {
          "assessmentArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "testRecommendations"
        ],
        "members": {
          "nextToken": {},
          "testRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "referenceId"
              ],
              "members": {
                "appComponentName": {},
                "dependsOnAlarms": {
                  "type": "list",
                  "member": {}
                },
                "description": {},
                "intent": {},
                "items": {
                  "shape": "S3r"
                },
                "name": {},
                "prerequisite": {},
                "recommendationId": {},
                "referenceId": {},
                "risk": {},
                "type": {}
              }
            }
          }
        }
      }
    },
    "ListUnsupportedAppVersionResources": {
      "http": {
        "requestUri": "/list-unsupported-app-version-resources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "resolutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resolutionId",
          "unsupportedResources"
        ],
        "members": {
          "nextToken": {},
          "resolutionId": {},
          "unsupportedResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "logicalResourceId",
                "physicalResourceId",
                "resourceType"
              ],
              "members": {
                "logicalResourceId": {
                  "shape": "S10"
                },
                "physicalResourceId": {
                  "shape": "S8"
                },
                "resourceType": {},
                "unsupportedResourceStatus": {}
              }
            }
          }
        }
      }
    },
    "PublishAppVersion": {
      "http": {
        "requestUri": "/publish-app-version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "appVersion": {}
        }
      }
    },
    "PutDraftAppVersionTemplate": {
      "http": {
        "requestUri": "/put-draft-app-version-template",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appTemplateBody"
        ],
        "members": {
          "appArn": {},
          "appTemplateBody": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "appArn": {},
          "appVersion": {}
        }
      }
    },
    "RemoveDraftAppVersionResourceMappings": {
      "http": {
        "requestUri": "/remove-draft-app-version-resource-mappings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "appRegistryAppNames": {
            "shape": "S5v"
          },
          "eksSourceNames": {
            "shape": "S5w"
          },
          "logicalStackNames": {
            "shape": "S5w"
          },
          "resourceGroupNames": {
            "shape": "S5v"
          },
          "resourceNames": {
            "shape": "S5v"
          },
          "terraformSourceNames": {
            "shape": "S5w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "appArn": {},
          "appVersion": {}
        }
      }
    },
    "ResolveAppVersionResources": {
      "http": {
        "requestUri": "/resolve-app-version-resources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "resolutionId",
          "status"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "resolutionId": {},
          "status": {}
        }
      }
    },
    "StartAppAssessment": {
      "http": {
        "requestUri": "/start-app-assessment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion",
          "assessmentName"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "assessmentName": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessment"
        ],
        "members": {
          "assessment": {
            "shape": "S2h"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {},
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApp": {
      "http": {
        "requestUri": "/update-app",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "appArn": {},
          "assessmentSchedule": {},
          "clearResiliencyPolicyArn": {
            "type": "boolean"
          },
          "description": {},
          "policyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "app"
        ],
        "members": {
          "app": {
            "shape": "Sm"
          }
        }
      }
    },
    "UpdateAppVersion": {
      "http": {
        "requestUri": "/update-app-version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {},
          "appVersion": {}
        }
      }
    },
    "UpdateAppVersionAppComponent": {
      "http": {
        "requestUri": "/update-app-version-app-component",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn",
          "id"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {},
          "id": {},
          "name": {},
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appComponent": {
            "shape": "Sx"
          },
          "appVersion": {}
        }
      }
    },
    "UpdateAppVersionResource": {
      "http": {
        "requestUri": "/update-app-version-resource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appArn"
        ],
        "members": {
          "additionalInfo": {
            "shape": "Ss"
          },
          "appArn": {},
          "appComponents": {
            "shape": "Sz"
          },
          "awsAccountId": {},
          "awsRegion": {},
          "excluded": {
            "type": "boolean"
          },
          "logicalResourceId": {
            "shape": "S10"
          },
          "physicalResourceId": {},
          "resourceName": {},
          "resourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "appArn",
          "appVersion"
        ],
        "members": {
          "appArn": {},
          "appVersion": {},
          "physicalResource": {
            "shape": "S13"
          }
        }
      }
    },
    "UpdateResiliencyPolicy": {
      "http": {
        "requestUri": "/update-resiliency-policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "policyArn"
        ],
        "members": {
          "dataLocationConstraint": {},
          "policy": {
            "shape": "S1k"
          },
          "policyArn": {},
          "policyDescription": {},
          "policyName": {},
          "tier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policy"
        ],
        "members": {
          "policy": {
            "shape": "S1q"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "mappingType",
          "physicalResourceId"
        ],
        "members": {
          "appRegistryAppName": {},
          "eksSourceName": {},
          "logicalStackName": {},
          "mappingType": {},
          "physicalResourceId": {
            "shape": "S8"
          },
          "resourceGroupName": {},
          "resourceName": {},
          "terraformSourceName": {}
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "identifier",
        "type"
      ],
      "members": {
        "awsAccountId": {},
        "awsRegion": {},
        "identifier": {},
        "type": {}
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "Sm": {
      "type": "structure",
      "required": [
        "appArn",
        "creationTime",
        "name"
      ],
      "members": {
        "appArn": {},
        "assessmentSchedule": {},
        "complianceStatus": {},
        "creationTime": {
          "type": "timestamp"
        },
        "description": {},
        "lastAppComplianceEvaluationTime": {
          "type": "timestamp"
        },
        "lastResiliencyScoreEvaluationTime": {
          "type": "timestamp"
        },
        "name": {},
        "policyArn": {},
        "resiliencyScore": {
          "type": "double"
        },
        "status": {},
        "tags": {
          "shape": "Si"
        }
      }
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "name",
        "type"
      ],
      "members": {
        "additionalInfo": {
          "shape": "Ss"
        },
        "id": {},
        "name": {},
        "type": {}
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S10": {
      "type": "structure",
      "required": [
        "identifier"
      ],
      "members": {
        "eksSourceName": {},
        "identifier": {},
        "logicalStackName": {},
        "resourceGroupName": {},
        "terraformSourceName": {}
      }
    },
    "S13": {
      "type": "structure",
      "required": [
        "logicalResourceId",
        "physicalResourceId",
        "resourceType"
      ],
      "members": {
        "additionalInfo": {
          "shape": "Ss"
        },
        "appComponents": {
          "shape": "S14"
        },
        "excluded": {
          "type": "boolean"
        },
        "logicalResourceId": {
          "shape": "S10"
        },
        "parentResourceName": {},
        "physicalResourceId": {
          "shape": "S8"
        },
        "resourceName": {},
        "resourceType": {},
        "sourceType": {}
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "shape": "Sx"
      }
    },
    "S19": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "list",
      "member": {}
    },
    "S1e": {
      "type": "structure",
      "required": [
        "assessmentArn",
        "format",
        "name",
        "recommendationTemplateArn",
        "recommendationTypes",
        "status"
      ],
      "members": {
        "appArn": {},
        "assessmentArn": {},
        "endTime": {
          "type": "timestamp"
        },
        "format": {},
        "message": {},
        "name": {},
        "needsReplacements": {
          "type": "boolean"
        },
        "recommendationIds": {
          "shape": "S19"
        },
        "recommendationTemplateArn": {},
        "recommendationTypes": {
          "shape": "S1b"
        },
        "startTime": {
          "type": "timestamp"
        },
        "status": {},
        "tags": {
          "shape": "Si"
        },
        "templatesLocation": {
          "type": "structure",
          "members": {
            "bucket": {},
            "prefix": {}
          }
        }
      }
    },
    "S1k": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "rpoInSecs",
          "rtoInSecs"
        ],
        "members": {
          "rpoInSecs": {
            "type": "integer"
          },
          "rtoInSecs": {
            "type": "integer"
          }
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "creationTime": {
          "type": "timestamp"
        },
        "dataLocationConstraint": {},
        "estimatedCostTier": {},
        "policy": {
          "shape": "S1k"
        },
        "policyArn": {},
        "policyDescription": {},
        "policyName": {},
        "tags": {
          "shape": "Si"
        },
        "tier": {}
      }
    },
    "S1y": {
      "type": "structure",
      "required": [
        "eksClusterArn",
        "namespace"
      ],
      "members": {
        "eksClusterArn": {},
        "namespace": {}
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "s3StateFileUrl"
      ],
      "members": {
        "s3StateFileUrl": {}
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "importType"
      ],
      "members": {
        "eksSourceClusterNamespace": {
          "shape": "S1y"
        },
        "importType": {},
        "resourceCount": {
          "type": "integer"
        },
        "sourceArn": {},
        "sourceName": {},
        "terraformSource": {
          "shape": "S20"
        }
      }
    },
    "S2h": {
      "type": "structure",
      "required": [
        "assessmentArn",
        "assessmentStatus",
        "invoker"
      ],
      "members": {
        "appArn": {},
        "appVersion": {},
        "assessmentArn": {},
        "assessmentName": {},
        "assessmentStatus": {},
        "compliance": {
          "shape": "S2i"
        },
        "complianceStatus": {},
        "cost": {
          "shape": "S2l"
        },
        "endTime": {
          "type": "timestamp"
        },
        "invoker": {},
        "message": {},
        "policy": {
          "shape": "S1q"
        },
        "resiliencyScore": {
          "shape": "S2p"
        },
        "resourceErrorsDetails": {
          "type": "structure",
          "members": {
            "hasMoreErrors": {
              "type": "boolean"
            },
            "resourceErrors": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "logicalResourceId": {},
                  "physicalResourceId": {},
                  "reason": {}
                }
              }
            }
          }
        },
        "startTime": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "Si"
        }
      }
    },
    "S2i": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "complianceStatus"
        ],
        "members": {
          "achievableRpoInSecs": {
            "type": "integer"
          },
          "achievableRtoInSecs": {
            "type": "integer"
          },
          "complianceStatus": {},
          "currentRpoInSecs": {
            "type": "integer"
          },
          "currentRtoInSecs": {
            "type": "integer"
          },
          "message": {},
          "rpoDescription": {},
          "rpoReferenceId": {},
          "rtoDescription": {},
          "rtoReferenceId": {}
        }
      }
    },
    "S2l": {
      "type": "structure",
      "required": [
        "amount",
        "currency",
        "frequency"
      ],
      "members": {
        "amount": {
          "type": "double"
        },
        "currency": {},
        "frequency": {}
      }
    },
    "S2p": {
      "type": "structure",
      "required": [
        "disruptionScore",
        "score"
      ],
      "members": {
        "disruptionScore": {
          "type": "map",
          "key": {},
          "value": {
            "type": "double"
          }
        },
        "score": {
          "type": "double"
        }
      }
    },
    "S3d": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "eksClusterArn",
          "namespaces"
        ],
        "members": {
          "eksClusterArn": {},
          "namespaces": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S3h": {
      "type": "list",
      "member": {}
    },
    "S3i": {
      "type": "list",
      "member": {
        "shape": "S20"
      }
    },
    "S3r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "alreadyImplemented": {
            "type": "boolean"
          },
          "resourceId": {},
          "targetAccountId": {},
          "targetRegion": {}
        }
      }
    },
    "S54": {
      "type": "list",
      "member": {
        "shape": "S1q"
      }
    },
    "S5v": {
      "type": "list",
      "member": {}
    },
    "S5w": {
      "type": "list",
      "member": {}
    }
  }
}