{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-12-15",
    "endpointPrefix": "lookoutequipment",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "LookoutEquipment",
    "serviceFullName": "Amazon Lookout for Equipment",
    "serviceId": "LookoutEquipment",
    "signatureVersion": "v4",
    "targetPrefix": "AWSLookoutEquipmentFrontendService",
    "uid": "lookoutequipment-2020-12-15"
  },
  "operations": {
    "CreateDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "ClientToken"
        ],
        "members": {
          "DatasetName": {},
          "DatasetSchema": {
            "shape": "S3"
          },
          "ServerSideKmsKeyId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetName": {},
          "DatasetArn": {},
          "Status": {}
        }
      }
    },
    "CreateInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName",
          "InferenceSchedulerName",
          "DataUploadFrequency",
          "DataInputConfiguration",
          "DataOutputConfiguration",
          "RoleArn",
          "ClientToken"
        ],
        "members": {
          "ModelName": {},
          "InferenceSchedulerName": {},
          "DataDelayOffsetInMinutes": {
            "type": "long"
          },
          "DataUploadFrequency": {},
          "DataInputConfiguration": {
            "shape": "Sj"
          },
          "DataOutputConfiguration": {
            "shape": "Sr"
          },
          "RoleArn": {},
          "ServerSideKmsKeyId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InferenceSchedulerArn": {},
          "InferenceSchedulerName": {},
          "Status": {}
        }
      }
    },
    "CreateLabel": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName",
          "StartTime",
          "EndTime",
          "Rating",
          "ClientToken"
        ],
        "members": {
          "LabelGroupName": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Rating": {},
          "FaultCode": {},
          "Notes": {},
          "Equipment": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LabelId": {}
        }
      }
    },
    "CreateLabelGroup": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName",
          "ClientToken"
        ],
        "members": {
          "LabelGroupName": {},
          "FaultCodes": {
            "shape": "S17"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LabelGroupName": {},
          "LabelGroupArn": {}
        }
      }
    },
    "CreateModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName",
          "DatasetName",
          "ClientToken"
        ],
        "members": {
          "ModelName": {},
          "DatasetName": {},
          "DatasetSchema": {
            "shape": "S3"
          },
          "LabelsInputConfiguration": {
            "shape": "S1c"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TrainingDataStartTime": {
            "type": "timestamp"
          },
          "TrainingDataEndTime": {
            "type": "timestamp"
          },
          "EvaluationDataStartTime": {
            "type": "timestamp"
          },
          "EvaluationDataEndTime": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "DataPreProcessingConfiguration": {
            "shape": "S1e"
          },
          "ServerSideKmsKeyId": {},
          "Tags": {
            "shape": "S7"
          },
          "OffCondition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "Status": {}
        }
      }
    },
    "DeleteDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName"
        ],
        "members": {
          "DatasetName": {}
        }
      }
    },
    "DeleteInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      }
    },
    "DeleteLabel": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName",
          "LabelId"
        ],
        "members": {
          "LabelGroupName": {},
          "LabelId": {}
        }
      }
    },
    "DeleteLabelGroup": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName"
        ],
        "members": {
          "LabelGroupName": {}
        }
      }
    },
    "DeleteModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      }
    },
    "DescribeDataIngestionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "DatasetArn": {},
          "IngestionInputConfiguration": {
            "shape": "S1t"
          },
          "RoleArn": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "Status": {},
          "FailedReason": {},
          "DataQualitySummary": {
            "shape": "S1y"
          },
          "IngestedFilesSummary": {
            "shape": "S27"
          },
          "StatusDetail": {},
          "IngestedDataSize": {
            "type": "long"
          },
          "DataStartTime": {
            "type": "timestamp"
          },
          "DataEndTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName"
        ],
        "members": {
          "DatasetName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetName": {},
          "DatasetArn": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Status": {},
          "Schema": {
            "jsonvalue": true
          },
          "ServerSideKmsKeyId": {},
          "IngestionInputConfiguration": {
            "shape": "S1t"
          },
          "DataQualitySummary": {
            "shape": "S1y"
          },
          "IngestedFilesSummary": {
            "shape": "S27"
          },
          "RoleArn": {},
          "DataStartTime": {
            "type": "timestamp"
          },
          "DataEndTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "ModelName": {},
          "InferenceSchedulerName": {},
          "InferenceSchedulerArn": {},
          "Status": {},
          "DataDelayOffsetInMinutes": {
            "type": "long"
          },
          "DataUploadFrequency": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "DataInputConfiguration": {
            "shape": "Sj"
          },
          "DataOutputConfiguration": {
            "shape": "Sr"
          },
          "RoleArn": {},
          "ServerSideKmsKeyId": {},
          "LatestInferenceResult": {}
        }
      }
    },
    "DescribeLabel": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName",
          "LabelId"
        ],
        "members": {
          "LabelGroupName": {},
          "LabelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LabelGroupName": {},
          "LabelGroupArn": {},
          "LabelId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Rating": {},
          "FaultCode": {},
          "Notes": {},
          "Equipment": {},
          "CreatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLabelGroup": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName"
        ],
        "members": {
          "LabelGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LabelGroupName": {},
          "LabelGroupArn": {},
          "FaultCodes": {
            "shape": "S17"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelName": {},
          "ModelArn": {},
          "DatasetName": {},
          "DatasetArn": {},
          "Schema": {
            "jsonvalue": true
          },
          "LabelsInputConfiguration": {
            "shape": "S1c"
          },
          "TrainingDataStartTime": {
            "type": "timestamp"
          },
          "TrainingDataEndTime": {
            "type": "timestamp"
          },
          "EvaluationDataStartTime": {
            "type": "timestamp"
          },
          "EvaluationDataEndTime": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "DataPreProcessingConfiguration": {
            "shape": "S1e"
          },
          "Status": {},
          "TrainingExecutionStartTime": {
            "type": "timestamp"
          },
          "TrainingExecutionEndTime": {
            "type": "timestamp"
          },
          "FailedReason": {},
          "ModelMetrics": {
            "jsonvalue": true
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "ServerSideKmsKeyId": {},
          "OffCondition": {}
        }
      }
    },
    "ListDataIngestionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "DatasetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DataIngestionJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobId": {},
                "DatasetName": {},
                "DatasetArn": {},
                "IngestionInputConfiguration": {
                  "shape": "S1t"
                },
                "Status": {}
              }
            }
          }
        }
      }
    },
    "ListDatasets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DatasetNameBeginsWith": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DatasetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetName": {},
                "DatasetArn": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListInferenceEvents": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName",
          "IntervalStartTime",
          "IntervalEndTime"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "InferenceSchedulerName": {},
          "IntervalStartTime": {
            "type": "timestamp"
          },
          "IntervalEndTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "InferenceEventSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InferenceSchedulerArn": {},
                "InferenceSchedulerName": {},
                "EventStartTime": {
                  "type": "timestamp"
                },
                "EventEndTime": {
                  "type": "timestamp"
                },
                "Diagnostics": {},
                "EventDurationInSeconds": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "ListInferenceExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "InferenceSchedulerName": {},
          "DataStartTimeAfter": {
            "type": "timestamp"
          },
          "DataEndTimeBefore": {
            "type": "timestamp"
          },
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "InferenceExecutionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "InferenceSchedulerName": {},
                "InferenceSchedulerArn": {},
                "ScheduledStartTime": {
                  "type": "timestamp"
                },
                "DataStartTime": {
                  "type": "timestamp"
                },
                "DataEndTime": {
                  "type": "timestamp"
                },
                "DataInputConfiguration": {
                  "shape": "Sj"
                },
                "DataOutputConfiguration": {
                  "shape": "Sr"
                },
                "CustomerResultObject": {
                  "shape": "S29"
                },
                "Status": {},
                "FailedReason": {}
              }
            }
          }
        }
      }
    },
    "ListInferenceSchedulers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "InferenceSchedulerNameBeginsWith": {},
          "ModelName": {},
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "InferenceSchedulerSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "InferenceSchedulerName": {},
                "InferenceSchedulerArn": {},
                "Status": {},
                "DataDelayOffsetInMinutes": {
                  "type": "long"
                },
                "DataUploadFrequency": {},
                "LatestInferenceResult": {}
              }
            }
          }
        }
      }
    },
    "ListLabelGroups": {
      "input": {
        "type": "structure",
        "members": {
          "LabelGroupNameBeginsWith": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "LabelGroupSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LabelGroupName": {},
                "LabelGroupArn": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListLabels": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName"
        ],
        "members": {
          "LabelGroupName": {},
          "IntervalStartTime": {
            "type": "timestamp"
          },
          "IntervalEndTime": {
            "type": "timestamp"
          },
          "FaultCode": {},
          "Equipment": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "LabelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LabelGroupName": {},
                "LabelId": {},
                "LabelGroupArn": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Rating": {},
                "FaultCode": {},
                "Equipment": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListModels": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {},
          "ModelNameBeginsWith": {},
          "DatasetNameBeginsWith": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ModelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "DatasetName": {},
                "DatasetArn": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListSensorStatistics": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName"
        ],
        "members": {
          "DatasetName": {},
          "IngestionJobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SensorStatisticsSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ComponentName": {},
                "SensorName": {},
                "DataExists": {
                  "type": "boolean"
                },
                "MissingValues": {
                  "shape": "S3w"
                },
                "InvalidValues": {
                  "shape": "S3w"
                },
                "InvalidDateEntries": {
                  "shape": "S3w"
                },
                "DuplicateTimestamps": {
                  "shape": "S3w"
                },
                "CategoricalValues": {
                  "type": "structure",
                  "required": [
                    "Status"
                  ],
                  "members": {
                    "Status": {},
                    "NumberOfCategory": {
                      "type": "integer"
                    }
                  }
                },
                "MultipleOperatingModes": {
                  "type": "structure",
                  "required": [
                    "Status"
                  ],
                  "members": {
                    "Status": {}
                  }
                },
                "LargeTimestampGaps": {
                  "type": "structure",
                  "required": [
                    "Status"
                  ],
                  "members": {
                    "Status": {},
                    "NumberOfLargeTimestampGaps": {
                      "type": "integer"
                    },
                    "MaxTimestampGapInDays": {
                      "type": "integer"
                    }
                  }
                },
                "MonotonicValues": {
                  "type": "structure",
                  "required": [
                    "Status"
                  ],
                  "members": {
                    "Status": {},
                    "Monotonicity": {}
                  }
                },
                "DataStartTime": {
                  "type": "timestamp"
                },
                "DataEndTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "StartDataIngestionJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "IngestionInputConfiguration",
          "RoleArn",
          "ClientToken"
        ],
        "members": {
          "DatasetName": {},
          "IngestionInputConfiguration": {
            "shape": "S1t"
          },
          "RoleArn": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "Status": {}
        }
      }
    },
    "StartInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "ModelName": {},
          "InferenceSchedulerName": {},
          "InferenceSchedulerArn": {},
          "Status": {}
        }
      }
    },
    "StopInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "ModelName": {},
          "InferenceSchedulerName": {},
          "InferenceSchedulerArn": {},
          "Status": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {},
          "DataDelayOffsetInMinutes": {
            "type": "long"
          },
          "DataUploadFrequency": {},
          "DataInputConfiguration": {
            "shape": "Sj"
          },
          "DataOutputConfiguration": {
            "shape": "Sr"
          },
          "RoleArn": {}
        }
      }
    },
    "UpdateLabelGroup": {
      "input": {
        "type": "structure",
        "required": [
          "LabelGroupName"
        ],
        "members": {
          "LabelGroupName": {},
          "FaultCodes": {
            "shape": "S17"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "InlineDataSchema": {
          "jsonvalue": true
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "S3InputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        },
        "InputTimeZoneOffset": {},
        "InferenceInputNameConfiguration": {
          "type": "structure",
          "members": {
            "TimestampFormat": {},
            "ComponentTimestampDelimiter": {}
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "S3OutputConfiguration"
      ],
      "members": {
        "S3OutputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        },
        "KmsKeyId": {}
      }
    },
    "S17": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "S3InputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        },
        "LabelGroupName": {}
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "TargetSamplingRate": {}
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "S3InputConfiguration"
      ],
      "members": {
        "S3InputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {},
            "KeyPattern": {}
          }
        }
      }
    },
    "S1y": {
      "type": "structure",
      "required": [
        "InsufficientSensorData",
        "MissingSensorData",
        "InvalidSensorData",
        "UnsupportedTimestamps",
        "DuplicateTimestamps"
      ],
      "members": {
        "InsufficientSensorData": {
          "type": "structure",
          "required": [
            "MissingCompleteSensorData",
            "SensorsWithShortDateRange"
          ],
          "members": {
            "MissingCompleteSensorData": {
              "type": "structure",
              "required": [
                "AffectedSensorCount"
              ],
              "members": {
                "AffectedSensorCount": {
                  "type": "integer"
                }
              }
            },
            "SensorsWithShortDateRange": {
              "type": "structure",
              "required": [
                "AffectedSensorCount"
              ],
              "members": {
                "AffectedSensorCount": {
                  "type": "integer"
                }
              }
            }
          }
        },
        "MissingSensorData": {
          "type": "structure",
          "required": [
            "AffectedSensorCount",
            "TotalNumberOfMissingValues"
          ],
          "members": {
            "AffectedSensorCount": {
              "type": "integer"
            },
            "TotalNumberOfMissingValues": {
              "type": "integer"
            }
          }
        },
        "InvalidSensorData": {
          "type": "structure",
          "required": [
            "AffectedSensorCount",
            "TotalNumberOfInvalidValues"
          ],
          "members": {
            "AffectedSensorCount": {
              "type": "integer"
            },
            "TotalNumberOfInvalidValues": {
              "type": "integer"
            }
          }
        },
        "UnsupportedTimestamps": {
          "type": "structure",
          "required": [
            "TotalNumberOfUnsupportedTimestamps"
          ],
          "members": {
            "TotalNumberOfUnsupportedTimestamps": {
              "type": "integer"
            }
          }
        },
        "DuplicateTimestamps": {
          "type": "structure",
          "required": [
            "TotalNumberOfDuplicateTimestamps"
          ],
          "members": {
            "TotalNumberOfDuplicateTimestamps": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S27": {
      "type": "structure",
      "required": [
        "TotalNumberOfFiles",
        "IngestedNumberOfFiles"
      ],
      "members": {
        "TotalNumberOfFiles": {
          "type": "integer"
        },
        "IngestedNumberOfFiles": {
          "type": "integer"
        },
        "DiscardedFiles": {
          "type": "list",
          "member": {
            "shape": "S29"
          }
        }
      }
    },
    "S29": {
      "type": "structure",
      "required": [
        "Bucket",
        "Key"
      ],
      "members": {
        "Bucket": {},
        "Key": {}
      }
    },
    "S3w": {
      "type": "structure",
      "required": [
        "Count",
        "Percentage"
      ],
      "members": {
        "Count": {
          "type": "integer"
        },
        "Percentage": {
          "type": "float"
        }
      }
    }
  }
}