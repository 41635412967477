{
  "identityPoolId": "",

  "region": "",

  "identityPoolRegion": "",

  "userPoolId": "",

  "userPoolWebClientId": "",

  "mandatorySignIn": false,

  "authenticationFlowType": "USER_PASSWORD_AUTH",

  "federationTarget": "COGNITO_USER_POOLS",

  "aws_cognito_username_attributes": [
      "EMAIL"
  ],


  "oauth": {
    "domain": "",
    "scope": [
      "aws.cognito.signin.user.admin",
      "openid",
      "profile"
  ],
    "redirectSignIn": "",
    "redirectSignOut": "",
    "responseType": "token"
  },

  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": [
          "REQUIRES_LOWERCASE",
          "REQUIRES_UPPERCASE",
          "REQUIRES_NUMBERS",
          "REQUIRES_SYMBOLS"
      ]
  },

  "API": {
      "endpoints": [
        {
          "name": "core",
          "endpoint":
            "https://vkpevygehd.execute-api.eu-west-1.amazonaws.com/{ENV}"
        },
        {
          "name": "patterns",
          "endpoint":
            "https://ctqedhikcc.execute-api.eu-west-1.amazonaws.com/{ENV}"
        },
        {
          "name": "users",
          "endpoint":
            "https://ou989xgb5c.execute-api.eu-west-1.amazonaws.com/{ENV}"
        },
        {
          "name": "getenv",
          "endpoint":
            "https://s2g4xxro4h4dqn52ipxvt5kdpy0hbwka.lambda-url.eu-west-1.on.aws"
        }
      ]
  }
}