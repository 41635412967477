{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-05",
    "endpointPrefix": "transfer",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS Transfer",
    "serviceFullName": "AWS Transfer Family",
    "serviceId": "Transfer",
    "signatureVersion": "v4",
    "signingName": "transfer",
    "targetPrefix": "TransferService",
    "uid": "transfer-2018-11-05"
  },
  "operations": {
    "CreateAccess": {
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "ServerId",
          "ExternalId"
        ],
        "members": {
          "HomeDirectory": {},
          "HomeDirectoryType": {},
          "HomeDirectoryMappings": {
            "shape": "S4"
          },
          "Policy": {},
          "PosixProfile": {
            "shape": "S9"
          },
          "Role": {},
          "ServerId": {},
          "ExternalId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "ExternalId"
        ],
        "members": {
          "ServerId": {},
          "ExternalId": {}
        }
      }
    },
    "CreateAgreement": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "LocalProfileId",
          "PartnerProfileId",
          "BaseDirectory",
          "AccessRole"
        ],
        "members": {
          "Description": {},
          "ServerId": {},
          "LocalProfileId": {},
          "PartnerProfileId": {},
          "BaseDirectory": {},
          "AccessRole": {},
          "Status": {},
          "Tags": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AgreementId"
        ],
        "members": {
          "AgreementId": {}
        }
      }
    },
    "CreateConnector": {
      "input": {
        "type": "structure",
        "required": [
          "Url",
          "As2Config",
          "AccessRole"
        ],
        "members": {
          "Url": {},
          "As2Config": {
            "shape": "Ss"
          },
          "AccessRole": {},
          "LoggingRole": {},
          "Tags": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConnectorId"
        ],
        "members": {
          "ConnectorId": {}
        }
      }
    },
    "CreateProfile": {
      "input": {
        "type": "structure",
        "required": [
          "As2Id",
          "ProfileType"
        ],
        "members": {
          "As2Id": {},
          "ProfileType": {},
          "CertificateIds": {
            "shape": "S15"
          },
          "Tags": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {}
        }
      }
    },
    "CreateServer": {
      "input": {
        "type": "structure",
        "members": {
          "Certificate": {},
          "Domain": {},
          "EndpointDetails": {
            "shape": "S1b"
          },
          "EndpointType": {},
          "HostKey": {
            "shape": "S1l"
          },
          "IdentityProviderDetails": {
            "shape": "S1m"
          },
          "IdentityProviderType": {},
          "LoggingRole": {},
          "PostAuthenticationLoginBanner": {},
          "PreAuthenticationLoginBanner": {},
          "Protocols": {
            "shape": "S1t"
          },
          "ProtocolDetails": {
            "shape": "S1v"
          },
          "SecurityPolicyName": {},
          "Tags": {
            "shape": "Sk"
          },
          "WorkflowDetails": {
            "shape": "S22"
          },
          "StructuredLogDestinations": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "ServerId",
          "UserName"
        ],
        "members": {
          "HomeDirectory": {},
          "HomeDirectoryType": {},
          "HomeDirectoryMappings": {
            "shape": "S4"
          },
          "Policy": {},
          "PosixProfile": {
            "shape": "S9"
          },
          "Role": {},
          "ServerId": {},
          "SshPublicKeyBody": {},
          "Tags": {
            "shape": "Sk"
          },
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      }
    },
    "CreateWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Steps"
        ],
        "members": {
          "Description": {},
          "Steps": {
            "shape": "S2g"
          },
          "OnExceptionSteps": {
            "shape": "S2g"
          },
          "Tags": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "WorkflowId"
        ],
        "members": {
          "WorkflowId": {}
        }
      }
    },
    "DeleteAccess": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "ExternalId"
        ],
        "members": {
          "ServerId": {},
          "ExternalId": {}
        }
      }
    },
    "DeleteAgreement": {
      "input": {
        "type": "structure",
        "required": [
          "AgreementId",
          "ServerId"
        ],
        "members": {
          "AgreementId": {},
          "ServerId": {}
        }
      }
    },
    "DeleteCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateId"
        ],
        "members": {
          "CertificateId": {}
        }
      }
    },
    "DeleteConnector": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectorId"
        ],
        "members": {
          "ConnectorId": {}
        }
      }
    },
    "DeleteHostKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeyId"
        ],
        "members": {
          "ServerId": {},
          "HostKeyId": {}
        }
      }
    },
    "DeleteProfile": {
      "input": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {}
        }
      }
    },
    "DeleteServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "DeleteSshPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "SshPublicKeyId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "SshPublicKeyId": {},
          "UserName": {}
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      }
    },
    "DeleteWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "WorkflowId"
        ],
        "members": {
          "WorkflowId": {}
        }
      }
    },
    "DescribeAccess": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "ExternalId"
        ],
        "members": {
          "ServerId": {},
          "ExternalId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "Access"
        ],
        "members": {
          "ServerId": {},
          "Access": {
            "type": "structure",
            "members": {
              "HomeDirectory": {},
              "HomeDirectoryMappings": {
                "shape": "S4"
              },
              "HomeDirectoryType": {},
              "Policy": {},
              "PosixProfile": {
                "shape": "S9"
              },
              "Role": {},
              "ExternalId": {}
            }
          }
        }
      }
    },
    "DescribeAgreement": {
      "input": {
        "type": "structure",
        "required": [
          "AgreementId",
          "ServerId"
        ],
        "members": {
          "AgreementId": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Agreement"
        ],
        "members": {
          "Agreement": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "AgreementId": {},
              "Description": {},
              "Status": {},
              "ServerId": {},
              "LocalProfileId": {},
              "PartnerProfileId": {},
              "BaseDirectory": {},
              "AccessRole": {},
              "Tags": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "DescribeCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateId"
        ],
        "members": {
          "CertificateId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Certificate"
        ],
        "members": {
          "Certificate": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "CertificateId": {},
              "Usage": {},
              "Status": {},
              "Certificate": {
                "shape": "S3t"
              },
              "CertificateChain": {
                "shape": "S3u"
              },
              "ActiveDate": {
                "type": "timestamp"
              },
              "InactiveDate": {
                "type": "timestamp"
              },
              "Serial": {},
              "NotBeforeDate": {
                "type": "timestamp"
              },
              "NotAfterDate": {
                "type": "timestamp"
              },
              "Type": {},
              "Description": {},
              "Tags": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "DescribeConnector": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectorId"
        ],
        "members": {
          "ConnectorId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Connector"
        ],
        "members": {
          "Connector": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "ConnectorId": {},
              "Url": {},
              "As2Config": {
                "shape": "Ss"
              },
              "AccessRole": {},
              "LoggingRole": {},
              "Tags": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "DescribeExecution": {
      "input": {
        "type": "structure",
        "required": [
          "ExecutionId",
          "WorkflowId"
        ],
        "members": {
          "ExecutionId": {},
          "WorkflowId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "WorkflowId",
          "Execution"
        ],
        "members": {
          "WorkflowId": {},
          "Execution": {
            "type": "structure",
            "members": {
              "ExecutionId": {},
              "InitialFileLocation": {
                "shape": "S45"
              },
              "ServiceMetadata": {
                "shape": "S49"
              },
              "ExecutionRole": {},
              "LoggingConfiguration": {
                "type": "structure",
                "members": {
                  "LoggingRole": {},
                  "LogGroupName": {}
                }
              },
              "PosixProfile": {
                "shape": "S9"
              },
              "Status": {},
              "Results": {
                "type": "structure",
                "members": {
                  "Steps": {
                    "shape": "S4g"
                  },
                  "OnExceptionSteps": {
                    "shape": "S4g"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeHostKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeyId"
        ],
        "members": {
          "ServerId": {},
          "HostKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostKey"
        ],
        "members": {
          "HostKey": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "HostKeyId": {},
              "HostKeyFingerprint": {},
              "Description": {},
              "Type": {},
              "DateImported": {
                "type": "timestamp"
              },
              "Tags": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "DescribeProfile": {
      "input": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Profile"
        ],
        "members": {
          "Profile": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "ProfileId": {},
              "ProfileType": {},
              "As2Id": {},
              "CertificateIds": {
                "shape": "S15"
              },
              "Tags": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "DescribeSecurityPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecurityPolicyName"
        ],
        "members": {
          "SecurityPolicyName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SecurityPolicy"
        ],
        "members": {
          "SecurityPolicy": {
            "type": "structure",
            "required": [
              "SecurityPolicyName"
            ],
            "members": {
              "Fips": {
                "type": "boolean"
              },
              "SecurityPolicyName": {},
              "SshCiphers": {
                "shape": "S50"
              },
              "SshKexs": {
                "shape": "S50"
              },
              "SshMacs": {
                "shape": "S50"
              },
              "TlsCiphers": {
                "shape": "S50"
              }
            }
          }
        }
      }
    },
    "DescribeServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Server"
        ],
        "members": {
          "Server": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "Certificate": {},
              "ProtocolDetails": {
                "shape": "S1v"
              },
              "Domain": {},
              "EndpointDetails": {
                "shape": "S1b"
              },
              "EndpointType": {},
              "HostKeyFingerprint": {},
              "IdentityProviderDetails": {
                "shape": "S1m"
              },
              "IdentityProviderType": {},
              "LoggingRole": {},
              "PostAuthenticationLoginBanner": {},
              "PreAuthenticationLoginBanner": {},
              "Protocols": {
                "shape": "S1t"
              },
              "SecurityPolicyName": {},
              "ServerId": {},
              "State": {},
              "Tags": {
                "shape": "Sk"
              },
              "UserCount": {
                "type": "integer"
              },
              "WorkflowDetails": {
                "shape": "S22"
              },
              "StructuredLogDestinations": {
                "shape": "S27"
              }
            }
          }
        }
      }
    },
    "DescribeUser": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "User"
        ],
        "members": {
          "ServerId": {},
          "User": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "HomeDirectory": {},
              "HomeDirectoryMappings": {
                "shape": "S4"
              },
              "HomeDirectoryType": {},
              "Policy": {},
              "PosixProfile": {
                "shape": "S9"
              },
              "Role": {},
              "SshPublicKeys": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "DateImported",
                    "SshPublicKeyBody",
                    "SshPublicKeyId"
                  ],
                  "members": {
                    "DateImported": {
                      "type": "timestamp"
                    },
                    "SshPublicKeyBody": {},
                    "SshPublicKeyId": {}
                  }
                }
              },
              "Tags": {
                "shape": "Sk"
              },
              "UserName": {}
            }
          }
        }
      }
    },
    "DescribeWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "WorkflowId"
        ],
        "members": {
          "WorkflowId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workflow"
        ],
        "members": {
          "Workflow": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "Description": {},
              "Steps": {
                "shape": "S2g"
              },
              "OnExceptionSteps": {
                "shape": "S2g"
              },
              "WorkflowId": {},
              "Tags": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "ImportCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "Usage",
          "Certificate"
        ],
        "members": {
          "Usage": {},
          "Certificate": {
            "shape": "S3t"
          },
          "CertificateChain": {
            "shape": "S3u"
          },
          "PrivateKey": {
            "type": "string",
            "sensitive": true
          },
          "ActiveDate": {
            "type": "timestamp"
          },
          "InactiveDate": {
            "type": "timestamp"
          },
          "Description": {},
          "Tags": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CertificateId"
        ],
        "members": {
          "CertificateId": {}
        }
      }
    },
    "ImportHostKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeyBody"
        ],
        "members": {
          "ServerId": {},
          "HostKeyBody": {
            "shape": "S1l"
          },
          "Description": {},
          "Tags": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeyId"
        ],
        "members": {
          "ServerId": {},
          "HostKeyId": {}
        }
      }
    },
    "ImportSshPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "SshPublicKeyBody",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "SshPublicKeyBody": {},
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "SshPublicKeyId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "SshPublicKeyId": {},
          "UserName": {}
        }
      }
    },
    "ListAccesses": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "Accesses"
        ],
        "members": {
          "NextToken": {},
          "ServerId": {},
          "Accesses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "HomeDirectory": {},
                "HomeDirectoryType": {},
                "Role": {},
                "ExternalId": {}
              }
            }
          }
        }
      }
    },
    "ListAgreements": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Agreements"
        ],
        "members": {
          "NextToken": {},
          "Agreements": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "AgreementId": {},
                "Description": {},
                "Status": {},
                "ServerId": {},
                "LocalProfileId": {},
                "PartnerProfileId": {}
              }
            }
          }
        }
      }
    },
    "ListCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Certificates"
        ],
        "members": {
          "NextToken": {},
          "Certificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CertificateId": {},
                "Usage": {},
                "Status": {},
                "ActiveDate": {
                  "type": "timestamp"
                },
                "InactiveDate": {
                  "type": "timestamp"
                },
                "Type": {},
                "Description": {}
              }
            }
          }
        }
      }
    },
    "ListConnectors": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Connectors"
        ],
        "members": {
          "NextToken": {},
          "Connectors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "ConnectorId": {},
                "Url": {}
              }
            }
          }
        }
      }
    },
    "ListExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "WorkflowId"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "WorkflowId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "WorkflowId",
          "Executions"
        ],
        "members": {
          "NextToken": {},
          "WorkflowId": {},
          "Executions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExecutionId": {},
                "InitialFileLocation": {
                  "shape": "S45"
                },
                "ServiceMetadata": {
                  "shape": "S49"
                },
                "Status": {}
              }
            }
          }
        }
      }
    },
    "ListHostKeys": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeys"
        ],
        "members": {
          "NextToken": {},
          "ServerId": {},
          "HostKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn"
              ],
              "members": {
                "Arn": {},
                "HostKeyId": {},
                "Fingerprint": {},
                "Description": {},
                "Type": {},
                "DateImported": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ProfileType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Profiles"
        ],
        "members": {
          "NextToken": {},
          "Profiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "ProfileId": {},
                "As2Id": {},
                "ProfileType": {}
              }
            }
          }
        }
      }
    },
    "ListSecurityPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SecurityPolicyNames"
        ],
        "members": {
          "NextToken": {},
          "SecurityPolicyNames": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListServers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Servers"
        ],
        "members": {
          "NextToken": {},
          "Servers": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn"
              ],
              "members": {
                "Arn": {},
                "Domain": {},
                "IdentityProviderType": {},
                "EndpointType": {},
                "LoggingRole": {},
                "ServerId": {},
                "State": {},
                "UserCount": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "NextToken": {},
          "Tags": {
            "shape": "Sk"
          }
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "Users"
        ],
        "members": {
          "NextToken": {},
          "ServerId": {},
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn"
              ],
              "members": {
                "Arn": {},
                "HomeDirectory": {},
                "HomeDirectoryType": {},
                "Role": {},
                "SshPublicKeyCount": {
                  "type": "integer"
                },
                "UserName": {}
              }
            }
          }
        }
      }
    },
    "ListWorkflows": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workflows"
        ],
        "members": {
          "NextToken": {},
          "Workflows": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkflowId": {},
                "Description": {},
                "Arn": {}
              }
            }
          }
        }
      }
    },
    "SendWorkflowStepState": {
      "input": {
        "type": "structure",
        "required": [
          "WorkflowId",
          "ExecutionId",
          "Token",
          "Status"
        ],
        "members": {
          "WorkflowId": {},
          "ExecutionId": {},
          "Token": {},
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartFileTransfer": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectorId",
          "SendFilePaths"
        ],
        "members": {
          "ConnectorId": {},
          "SendFilePaths": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransferId"
        ],
        "members": {
          "TransferId": {}
        }
      }
    },
    "StartServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "StopServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Tags"
        ],
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "Sk"
          }
        }
      }
    },
    "TestIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "ServerProtocol": {},
          "SourceIp": {},
          "UserName": {},
          "UserPassword": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StatusCode",
          "Url"
        ],
        "members": {
          "Response": {},
          "StatusCode": {
            "type": "integer"
          },
          "Message": {},
          "Url": {}
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "TagKeys"
        ],
        "members": {
          "Arn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAccess": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "ExternalId"
        ],
        "members": {
          "HomeDirectory": {},
          "HomeDirectoryType": {},
          "HomeDirectoryMappings": {
            "shape": "S4"
          },
          "Policy": {},
          "PosixProfile": {
            "shape": "S9"
          },
          "Role": {},
          "ServerId": {},
          "ExternalId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "ExternalId"
        ],
        "members": {
          "ServerId": {},
          "ExternalId": {}
        }
      }
    },
    "UpdateAgreement": {
      "input": {
        "type": "structure",
        "required": [
          "AgreementId",
          "ServerId"
        ],
        "members": {
          "AgreementId": {},
          "ServerId": {},
          "Description": {},
          "Status": {},
          "LocalProfileId": {},
          "PartnerProfileId": {},
          "BaseDirectory": {},
          "AccessRole": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AgreementId"
        ],
        "members": {
          "AgreementId": {}
        }
      }
    },
    "UpdateCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateId"
        ],
        "members": {
          "CertificateId": {},
          "ActiveDate": {
            "type": "timestamp"
          },
          "InactiveDate": {
            "type": "timestamp"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CertificateId"
        ],
        "members": {
          "CertificateId": {}
        }
      }
    },
    "UpdateConnector": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectorId"
        ],
        "members": {
          "ConnectorId": {},
          "Url": {},
          "As2Config": {
            "shape": "Ss"
          },
          "AccessRole": {},
          "LoggingRole": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConnectorId"
        ],
        "members": {
          "ConnectorId": {}
        }
      }
    },
    "UpdateHostKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeyId",
          "Description"
        ],
        "members": {
          "ServerId": {},
          "HostKeyId": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "HostKeyId"
        ],
        "members": {
          "ServerId": {},
          "HostKeyId": {}
        }
      }
    },
    "UpdateProfile": {
      "input": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {},
          "CertificateIds": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {}
        }
      }
    },
    "UpdateServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "Certificate": {},
          "ProtocolDetails": {
            "shape": "S1v"
          },
          "EndpointDetails": {
            "shape": "S1b"
          },
          "EndpointType": {},
          "HostKey": {
            "shape": "S1l"
          },
          "IdentityProviderDetails": {
            "shape": "S1m"
          },
          "LoggingRole": {},
          "PostAuthenticationLoginBanner": {},
          "PreAuthenticationLoginBanner": {},
          "Protocols": {
            "shape": "S1t"
          },
          "SecurityPolicyName": {},
          "ServerId": {},
          "WorkflowDetails": {
            "shape": "S22"
          },
          "StructuredLogDestinations": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "UpdateUser": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "HomeDirectory": {},
          "HomeDirectoryType": {},
          "HomeDirectoryMappings": {
            "shape": "S4"
          },
          "Policy": {},
          "PosixProfile": {
            "shape": "S9"
          },
          "Role": {},
          "ServerId": {},
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Entry",
          "Target"
        ],
        "members": {
          "Entry": {},
          "Target": {}
        }
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "Uid",
        "Gid"
      ],
      "members": {
        "Uid": {
          "type": "long"
        },
        "Gid": {
          "type": "long"
        },
        "SecondaryGids": {
          "type": "list",
          "member": {
            "type": "long"
          }
        }
      }
    },
    "Sk": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "LocalProfileId": {},
        "PartnerProfileId": {},
        "MessageSubject": {},
        "Compression": {},
        "EncryptionAlgorithm": {},
        "SigningAlgorithm": {},
        "MdnSigningAlgorithm": {},
        "MdnResponse": {},
        "BasicAuthSecretId": {}
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "structure",
      "members": {
        "AddressAllocationIds": {
          "type": "list",
          "member": {}
        },
        "SubnetIds": {
          "type": "list",
          "member": {}
        },
        "VpcEndpointId": {},
        "VpcId": {},
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1l": {
      "type": "string",
      "sensitive": true
    },
    "S1m": {
      "type": "structure",
      "members": {
        "Url": {},
        "InvocationRole": {},
        "DirectoryId": {},
        "Function": {},
        "SftpAuthenticationMethods": {}
      }
    },
    "S1t": {
      "type": "list",
      "member": {}
    },
    "S1v": {
      "type": "structure",
      "members": {
        "PassiveIp": {},
        "TlsSessionResumptionMode": {},
        "SetStatOption": {},
        "As2Transports": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "OnUpload": {
          "type": "list",
          "member": {
            "shape": "S24"
          }
        },
        "OnPartialUpload": {
          "type": "list",
          "member": {
            "shape": "S24"
          }
        }
      }
    },
    "S24": {
      "type": "structure",
      "required": [
        "WorkflowId",
        "ExecutionRole"
      ],
      "members": {
        "WorkflowId": {},
        "ExecutionRole": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {}
    },
    "S2g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "CopyStepDetails": {
            "type": "structure",
            "members": {
              "Name": {},
              "DestinationFileLocation": {
                "shape": "S2l"
              },
              "OverwriteExisting": {},
              "SourceFileLocation": {}
            }
          },
          "CustomStepDetails": {
            "type": "structure",
            "members": {
              "Name": {},
              "Target": {},
              "TimeoutSeconds": {
                "type": "integer"
              },
              "SourceFileLocation": {}
            }
          },
          "DeleteStepDetails": {
            "type": "structure",
            "members": {
              "Name": {},
              "SourceFileLocation": {}
            }
          },
          "TagStepDetails": {
            "type": "structure",
            "members": {
              "Name": {},
              "Tags": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "Value"
                  ],
                  "members": {
                    "Key": {},
                    "Value": {}
                  }
                }
              },
              "SourceFileLocation": {}
            }
          },
          "DecryptStepDetails": {
            "type": "structure",
            "required": [
              "Type",
              "DestinationFileLocation"
            ],
            "members": {
              "Name": {},
              "Type": {},
              "SourceFileLocation": {},
              "OverwriteExisting": {},
              "DestinationFileLocation": {
                "shape": "S2l"
              }
            }
          }
        }
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "S3FileLocation": {
          "type": "structure",
          "members": {
            "Bucket": {},
            "Key": {}
          }
        },
        "EfsFileLocation": {
          "shape": "S2p"
        }
      }
    },
    "S2p": {
      "type": "structure",
      "members": {
        "FileSystemId": {},
        "Path": {}
      }
    },
    "S3t": {
      "type": "string",
      "sensitive": true
    },
    "S3u": {
      "type": "string",
      "sensitive": true
    },
    "S45": {
      "type": "structure",
      "members": {
        "S3FileLocation": {
          "type": "structure",
          "members": {
            "Bucket": {},
            "Key": {},
            "VersionId": {},
            "Etag": {}
          }
        },
        "EfsFileLocation": {
          "shape": "S2p"
        }
      }
    },
    "S49": {
      "type": "structure",
      "required": [
        "UserDetails"
      ],
      "members": {
        "UserDetails": {
          "type": "structure",
          "required": [
            "UserName",
            "ServerId"
          ],
          "members": {
            "UserName": {},
            "ServerId": {},
            "SessionId": {}
          }
        }
      }
    },
    "S4g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StepType": {},
          "Outputs": {},
          "Error": {
            "type": "structure",
            "required": [
              "Type",
              "Message"
            ],
            "members": {
              "Type": {},
              "Message": {}
            }
          }
        }
      }
    },
    "S50": {
      "type": "list",
      "member": {}
    }
  }
}