{
  "pagination": {
    "ListCollaborations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationList"
    },
    "ListConfiguredTableAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "configuredTableAssociationSummaries"
    },
    "ListConfiguredTables": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "configuredTableSummaries"
    },
    "ListMembers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "memberSummaries"
    },
    "ListMemberships": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "membershipSummaries"
    },
    "ListProtectedQueries": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "protectedQueries"
    },
    "ListSchemas": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "schemaSummaries"
    }
  }
}
