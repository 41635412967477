{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2022-11-28",
    "endpointPrefix": "omics",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Omics",
    "serviceId": "Omics",
    "signatureVersion": "v4",
    "signingName": "omics",
    "uid": "omics-2022-11-28"
  },
  "operations": {
    "AbortMultipartReadSetUpload": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sequencestore/{sequenceStoreId}/upload/{uploadId}/abort",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "uploadId"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "uploadId": {
            "location": "uri",
            "locationName": "uploadId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "BatchDeleteReadSet": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/readset/batch/delete",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ids",
          "sequenceStoreId"
        ],
        "members": {
          "ids": {
            "type": "list",
            "member": {}
          },
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "code",
                "message"
              ],
              "members": {
                "id": {},
                "code": {},
                "message": {}
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      },
      "idempotent": true
    },
    "CancelAnnotationImportJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/import/annotation/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      },
      "idempotent": true
    },
    "CancelRun": {
      "http": {
        "requestUri": "/run/{id}/cancel",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "CancelVariantImportJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/import/variant/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      },
      "idempotent": true
    },
    "CompleteMultipartReadSetUpload": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/upload/{uploadId}/complete",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "uploadId",
          "parts"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "uploadId": {
            "location": "uri",
            "locationName": "uploadId"
          },
          "parts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "partNumber",
                "partSource",
                "checksum"
              ],
              "members": {
                "partNumber": {
                  "type": "integer"
                },
                "partSource": {},
                "checksum": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "readSetId"
        ],
        "members": {
          "readSetId": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "storage-"
      }
    },
    "CreateAnnotationStore": {
      "http": {
        "requestUri": "/annotationStore",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "storeFormat"
        ],
        "members": {
          "reference": {
            "shape": "Sq"
          },
          "name": {},
          "description": {},
          "tags": {
            "shape": "Su"
          },
          "sseConfig": {
            "shape": "Sx"
          },
          "storeFormat": {},
          "storeOptions": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "status",
          "name",
          "creationTime"
        ],
        "members": {
          "id": {},
          "reference": {
            "shape": "Sq"
          },
          "storeFormat": {},
          "storeOptions": {
            "shape": "S11"
          },
          "status": {},
          "name": {},
          "creationTime": {
            "shape": "S1d"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "CreateMultipartReadSetUpload": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/upload",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "sourceFileType",
          "subjectId",
          "sampleId",
          "referenceArn",
          "name"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "clientToken": {},
          "sourceFileType": {},
          "subjectId": {},
          "sampleId": {},
          "generatedFrom": {},
          "referenceArn": {},
          "name": {},
          "description": {},
          "tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "uploadId",
          "sourceFileType",
          "subjectId",
          "sampleId",
          "referenceArn",
          "creationTime"
        ],
        "members": {
          "sequenceStoreId": {},
          "uploadId": {},
          "sourceFileType": {},
          "subjectId": {},
          "sampleId": {},
          "generatedFrom": {},
          "referenceArn": {},
          "name": {},
          "description": {},
          "tags": {
            "shape": "Su"
          },
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "CreateReferenceStore": {
      "http": {
        "requestUri": "/referencestore",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "tags": {
            "shape": "Su"
          },
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "arn",
          "creationTime"
        ],
        "members": {
          "id": {},
          "arn": {},
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "CreateRunGroup": {
      "http": {
        "requestUri": "/runGroup",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "requestId"
        ],
        "members": {
          "name": {},
          "maxCpus": {
            "type": "integer"
          },
          "maxRuns": {
            "type": "integer"
          },
          "maxDuration": {
            "type": "integer"
          },
          "tags": {
            "shape": "Su"
          },
          "requestId": {
            "idempotencyToken": true
          },
          "maxGpus": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "tags": {
            "shape": "Su"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "CreateSequenceStore": {
      "http": {
        "requestUri": "/sequencestore",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "tags": {
            "shape": "Su"
          },
          "clientToken": {},
          "fallbackLocation": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "arn",
          "creationTime"
        ],
        "members": {
          "id": {},
          "arn": {},
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "creationTime": {
            "shape": "S1n"
          },
          "fallbackLocation": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "CreateVariantStore": {
      "http": {
        "requestUri": "/variantStore",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "reference"
        ],
        "members": {
          "reference": {
            "shape": "Sq"
          },
          "name": {},
          "description": {},
          "tags": {
            "shape": "Su"
          },
          "sseConfig": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "status",
          "name",
          "creationTime"
        ],
        "members": {
          "id": {},
          "reference": {
            "shape": "Sq"
          },
          "status": {},
          "name": {},
          "creationTime": {
            "shape": "S1d"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "CreateWorkflow": {
      "http": {
        "requestUri": "/workflow",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "requestId"
        ],
        "members": {
          "name": {},
          "description": {},
          "engine": {},
          "definitionZip": {
            "type": "blob"
          },
          "definitionUri": {},
          "main": {},
          "parameterTemplate": {
            "shape": "S2k"
          },
          "storageCapacity": {
            "type": "integer"
          },
          "tags": {
            "shape": "Su"
          },
          "requestId": {
            "idempotencyToken": true
          },
          "accelerators": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "status": {},
          "tags": {
            "shape": "Su"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "DeleteAnnotationStore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/annotationStore/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      },
      "idempotent": true
    },
    "DeleteReference": {
      "http": {
        "method": "DELETE",
        "requestUri": "/referencestore/{referenceStoreId}/reference/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "referenceStoreId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      },
      "idempotent": true
    },
    "DeleteReferenceStore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/referencestore/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      },
      "idempotent": true
    },
    "DeleteRun": {
      "http": {
        "method": "DELETE",
        "requestUri": "/run/{id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      },
      "idempotent": true
    },
    "DeleteRunGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/runGroup/{id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      },
      "idempotent": true
    },
    "DeleteSequenceStore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sequencestore/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      },
      "idempotent": true
    },
    "DeleteVariantStore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/variantStore/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      },
      "idempotent": true
    },
    "DeleteWorkflow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workflow/{id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      },
      "idempotent": true
    },
    "GetAnnotationImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/import/annotation/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "destinationName",
          "roleArn",
          "status",
          "statusMessage",
          "creationTime",
          "updateTime",
          "completionTime",
          "items",
          "runLeftNormalization",
          "formatOptions"
        ],
        "members": {
          "id": {},
          "destinationName": {},
          "roleArn": {},
          "status": {},
          "statusMessage": {},
          "creationTime": {
            "shape": "S1d"
          },
          "updateTime": {
            "shape": "S3h"
          },
          "completionTime": {
            "shape": "S3i"
          },
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "source",
                "jobStatus"
              ],
              "members": {
                "source": {},
                "jobStatus": {}
              }
            }
          },
          "runLeftNormalization": {
            "type": "boolean"
          },
          "formatOptions": {
            "shape": "S3n"
          },
          "annotationFields": {
            "shape": "S40"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "GetAnnotationStore": {
      "http": {
        "method": "GET",
        "requestUri": "/annotationStore/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "reference",
          "status",
          "storeArn",
          "name",
          "description",
          "sseConfig",
          "creationTime",
          "updateTime",
          "tags",
          "statusMessage",
          "storeSizeBytes"
        ],
        "members": {
          "id": {},
          "reference": {
            "shape": "Sq"
          },
          "status": {},
          "storeArn": {},
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "creationTime": {
            "shape": "S1d"
          },
          "updateTime": {
            "shape": "S3h"
          },
          "tags": {
            "shape": "Su"
          },
          "storeOptions": {
            "shape": "S11"
          },
          "storeFormat": {},
          "statusMessage": {},
          "storeSizeBytes": {
            "type": "long"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "GetReadSet": {
      "http": {
        "method": "GET",
        "requestUri": "/sequencestore/{sequenceStoreId}/readset/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "partNumber"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "file": {
            "location": "querystring",
            "locationName": "file"
          },
          "partNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "payload": {
            "type": "blob",
            "streaming": true
          }
        },
        "payload": "payload"
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "storage-"
      }
    },
    "GetReadSetActivationJob": {
      "http": {
        "method": "GET",
        "requestUri": "/sequencestore/{sequenceStoreId}/activationjob/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "status",
          "creationTime"
        ],
        "members": {
          "id": {},
          "sequenceStoreId": {},
          "status": {},
          "statusMessage": {},
          "creationTime": {
            "shape": "S1n"
          },
          "completionTime": {
            "shape": "S1n"
          },
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "readSetId",
                "status"
              ],
              "members": {
                "readSetId": {},
                "status": {},
                "statusMessage": {}
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetReadSetExportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/sequencestore/{sequenceStoreId}/exportjob/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "id"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "destination",
          "status",
          "creationTime"
        ],
        "members": {
          "id": {},
          "sequenceStoreId": {},
          "destination": {},
          "status": {},
          "statusMessage": {},
          "creationTime": {
            "shape": "S1n"
          },
          "completionTime": {
            "shape": "S1n"
          },
          "readSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "status"
              ],
              "members": {
                "id": {},
                "status": {},
                "statusMessage": {}
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetReadSetImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/sequencestore/{sequenceStoreId}/importjob/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "roleArn",
          "status",
          "creationTime",
          "sources"
        ],
        "members": {
          "id": {},
          "sequenceStoreId": {},
          "roleArn": {},
          "status": {},
          "statusMessage": {},
          "creationTime": {
            "shape": "S1n"
          },
          "completionTime": {
            "shape": "S1n"
          },
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sourceFiles",
                "sourceFileType",
                "status",
                "subjectId",
                "sampleId"
              ],
              "members": {
                "sourceFiles": {
                  "shape": "S4y"
                },
                "sourceFileType": {},
                "status": {},
                "statusMessage": {},
                "subjectId": {},
                "sampleId": {},
                "generatedFrom": {},
                "referenceArn": {},
                "name": {},
                "description": {},
                "tags": {
                  "shape": "Su"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetReadSetMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/sequencestore/{sequenceStoreId}/readset/{id}/metadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "arn",
          "sequenceStoreId",
          "status",
          "fileType",
          "creationTime"
        ],
        "members": {
          "id": {},
          "arn": {},
          "sequenceStoreId": {},
          "subjectId": {},
          "sampleId": {},
          "status": {},
          "name": {},
          "description": {},
          "fileType": {},
          "creationTime": {
            "shape": "S1n"
          },
          "sequenceInformation": {
            "shape": "S54"
          },
          "referenceArn": {},
          "files": {
            "type": "structure",
            "members": {
              "source1": {
                "shape": "S56"
              },
              "source2": {
                "shape": "S56"
              },
              "index": {
                "shape": "S56"
              }
            }
          },
          "statusMessage": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetReference": {
      "http": {
        "method": "GET",
        "requestUri": "/referencestore/{referenceStoreId}/reference/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "referenceStoreId",
          "partNumber"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          },
          "range": {
            "location": "header",
            "locationName": "Range"
          },
          "partNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          },
          "file": {
            "location": "querystring",
            "locationName": "file"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "payload": {
            "type": "blob",
            "streaming": true
          }
        },
        "payload": "payload"
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "storage-"
      }
    },
    "GetReferenceImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/referencestore/{referenceStoreId}/importjob/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "referenceStoreId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "referenceStoreId",
          "roleArn",
          "status",
          "creationTime",
          "sources"
        ],
        "members": {
          "id": {},
          "referenceStoreId": {},
          "roleArn": {},
          "status": {},
          "statusMessage": {},
          "creationTime": {
            "shape": "S1n"
          },
          "completionTime": {
            "shape": "S1n"
          },
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "status"
              ],
              "members": {
                "sourceFile": {},
                "status": {},
                "statusMessage": {},
                "name": {},
                "description": {},
                "tags": {
                  "shape": "Su"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetReferenceMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/referencestore/{referenceStoreId}/reference/{id}/metadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "referenceStoreId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "arn",
          "referenceStoreId",
          "md5",
          "creationTime",
          "updateTime"
        ],
        "members": {
          "id": {},
          "arn": {},
          "referenceStoreId": {},
          "md5": {},
          "status": {},
          "name": {},
          "description": {},
          "creationTime": {
            "shape": "S1n"
          },
          "updateTime": {
            "shape": "S1n"
          },
          "files": {
            "type": "structure",
            "members": {
              "source": {
                "shape": "S56"
              },
              "index": {
                "shape": "S56"
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetReferenceStore": {
      "http": {
        "method": "GET",
        "requestUri": "/referencestore/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "arn",
          "creationTime"
        ],
        "members": {
          "id": {},
          "arn": {},
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetRun": {
      "http": {
        "method": "GET",
        "requestUri": "/run/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "export": {
            "location": "querystring",
            "locationName": "export",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "status": {},
          "workflowId": {},
          "workflowType": {},
          "runId": {},
          "roleArn": {},
          "name": {},
          "runGroupId": {},
          "priority": {
            "type": "integer"
          },
          "definition": {},
          "digest": {},
          "parameters": {
            "shape": "S67"
          },
          "storageCapacity": {
            "type": "integer"
          },
          "outputUri": {},
          "logLevel": {},
          "resourceDigests": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "startedBy": {},
          "creationTime": {
            "shape": "S6f"
          },
          "startTime": {
            "shape": "S6f"
          },
          "stopTime": {
            "shape": "S6f"
          },
          "statusMessage": {},
          "tags": {
            "shape": "Su"
          },
          "accelerators": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "GetRunGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/runGroup/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "name": {},
          "maxCpus": {
            "type": "integer"
          },
          "maxRuns": {
            "type": "integer"
          },
          "maxDuration": {
            "type": "integer"
          },
          "creationTime": {
            "shape": "S6m"
          },
          "tags": {
            "shape": "Su"
          },
          "maxGpus": {
            "type": "integer"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "GetRunTask": {
      "http": {
        "method": "GET",
        "requestUri": "/run/{id}/task/{taskId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "taskId"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {},
          "status": {},
          "name": {},
          "cpus": {
            "type": "integer"
          },
          "memory": {
            "type": "integer"
          },
          "creationTime": {
            "shape": "S6v"
          },
          "startTime": {
            "shape": "S6v"
          },
          "stopTime": {
            "shape": "S6v"
          },
          "statusMessage": {},
          "logStream": {},
          "gpus": {
            "type": "integer"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "GetSequenceStore": {
      "http": {
        "method": "GET",
        "requestUri": "/sequencestore/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "arn",
          "creationTime"
        ],
        "members": {
          "id": {},
          "arn": {},
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "creationTime": {
            "shape": "S1n"
          },
          "fallbackLocation": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "GetVariantImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/import/variant/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "destinationName",
          "roleArn",
          "status",
          "statusMessage",
          "creationTime",
          "updateTime",
          "items",
          "runLeftNormalization"
        ],
        "members": {
          "id": {},
          "destinationName": {},
          "roleArn": {},
          "status": {},
          "statusMessage": {},
          "creationTime": {
            "shape": "S1d"
          },
          "updateTime": {
            "shape": "S3h"
          },
          "completionTime": {
            "shape": "S3i"
          },
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "source",
                "jobStatus"
              ],
              "members": {
                "source": {},
                "jobStatus": {},
                "statusMessage": {}
              }
            }
          },
          "runLeftNormalization": {
            "type": "boolean"
          },
          "annotationFields": {
            "shape": "S40"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "GetVariantStore": {
      "http": {
        "method": "GET",
        "requestUri": "/variantStore/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "reference",
          "status",
          "storeArn",
          "name",
          "description",
          "sseConfig",
          "creationTime",
          "updateTime",
          "tags",
          "statusMessage",
          "storeSizeBytes"
        ],
        "members": {
          "id": {},
          "reference": {
            "shape": "Sq"
          },
          "status": {},
          "storeArn": {},
          "name": {},
          "description": {},
          "sseConfig": {
            "shape": "Sx"
          },
          "creationTime": {
            "shape": "S1d"
          },
          "updateTime": {
            "shape": "S3h"
          },
          "tags": {
            "shape": "Su"
          },
          "statusMessage": {},
          "storeSizeBytes": {
            "type": "long"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "GetWorkflow": {
      "http": {
        "method": "GET",
        "requestUri": "/workflow/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          },
          "export": {
            "location": "querystring",
            "locationName": "export",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "status": {},
          "type": {},
          "name": {},
          "description": {},
          "engine": {},
          "definition": {},
          "main": {},
          "digest": {},
          "parameterTemplate": {
            "shape": "S2k"
          },
          "storageCapacity": {
            "type": "integer"
          },
          "creationTime": {
            "shape": "S7c"
          },
          "statusMessage": {},
          "tags": {
            "shape": "Su"
          },
          "metadata": {
            "shape": "S7e"
          },
          "accelerators": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "ListAnnotationImportJobs": {
      "http": {
        "requestUri": "/import/annotations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ids": {
            "type": "list",
            "member": {}
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {},
              "storeName": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "annotationImportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "destinationName",
                "roleArn",
                "status",
                "creationTime",
                "updateTime"
              ],
              "members": {
                "id": {},
                "destinationName": {},
                "roleArn": {},
                "status": {},
                "creationTime": {
                  "shape": "S1d"
                },
                "updateTime": {
                  "shape": "S3h"
                },
                "completionTime": {
                  "shape": "S3i"
                },
                "runLeftNormalization": {
                  "type": "boolean"
                },
                "annotationFields": {
                  "shape": "S40"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "ListAnnotationStores": {
      "http": {
        "requestUri": "/annotationStores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ids": {
            "type": "list",
            "member": {}
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "annotationStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "reference",
                "status",
                "storeArn",
                "name",
                "storeFormat",
                "description",
                "sseConfig",
                "creationTime",
                "updateTime",
                "statusMessage",
                "storeSizeBytes"
              ],
              "members": {
                "id": {},
                "reference": {
                  "shape": "Sq"
                },
                "status": {},
                "storeArn": {},
                "name": {},
                "storeFormat": {},
                "description": {},
                "sseConfig": {
                  "shape": "Sx"
                },
                "creationTime": {
                  "shape": "S1d"
                },
                "updateTime": {
                  "shape": "S3h"
                },
                "statusMessage": {},
                "storeSizeBytes": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "ListMultipartReadSetUploads": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/uploads",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "uploads": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sequenceStoreId",
                "uploadId",
                "sourceFileType",
                "subjectId",
                "sampleId",
                "generatedFrom",
                "referenceArn",
                "creationTime"
              ],
              "members": {
                "sequenceStoreId": {},
                "uploadId": {},
                "sourceFileType": {},
                "subjectId": {},
                "sampleId": {},
                "generatedFrom": {},
                "referenceArn": {},
                "name": {},
                "description": {},
                "tags": {
                  "shape": "Su"
                },
                "creationTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReadSetActivationJobs": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/activationjobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "activationJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "sequenceStoreId",
                "status",
                "creationTime"
              ],
              "members": {
                "id": {},
                "sequenceStoreId": {},
                "status": {},
                "creationTime": {
                  "shape": "S1n"
                },
                "completionTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReadSetExportJobs": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/exportjobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "exportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "sequenceStoreId",
                "destination",
                "status",
                "creationTime"
              ],
              "members": {
                "id": {},
                "sequenceStoreId": {},
                "destination": {},
                "status": {},
                "creationTime": {
                  "shape": "S1n"
                },
                "completionTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReadSetImportJobs": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/importjobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "importJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "sequenceStoreId",
                "roleArn",
                "status",
                "creationTime"
              ],
              "members": {
                "id": {},
                "sequenceStoreId": {},
                "roleArn": {},
                "status": {},
                "creationTime": {
                  "shape": "S1n"
                },
                "completionTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReadSetUploadParts": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/upload/{uploadId}/parts",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "uploadId",
          "partSource"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "uploadId": {
            "location": "uri",
            "locationName": "uploadId"
          },
          "partSource": {},
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "parts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "partNumber",
                "partSize",
                "partSource",
                "checksum"
              ],
              "members": {
                "partNumber": {
                  "type": "integer"
                },
                "partSize": {
                  "type": "long"
                },
                "partSource": {},
                "checksum": {},
                "creationTime": {
                  "shape": "S1n"
                },
                "lastUpdatedTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReadSets": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/readsets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "name": {},
              "status": {},
              "referenceArn": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              },
              "sampleId": {},
              "subjectId": {},
              "generatedFrom": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "readSets"
        ],
        "members": {
          "nextToken": {},
          "readSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "sequenceStoreId",
                "status",
                "fileType",
                "creationTime"
              ],
              "members": {
                "id": {},
                "arn": {},
                "sequenceStoreId": {},
                "subjectId": {},
                "sampleId": {},
                "status": {},
                "name": {},
                "description": {},
                "referenceArn": {},
                "fileType": {},
                "sequenceInformation": {
                  "shape": "S54"
                },
                "creationTime": {
                  "shape": "S1n"
                },
                "statusMessage": {}
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReferenceImportJobs": {
      "http": {
        "requestUri": "/referencestore/{referenceStoreId}/importjobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "referenceStoreId"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "importJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "referenceStoreId",
                "roleArn",
                "status",
                "creationTime"
              ],
              "members": {
                "id": {},
                "referenceStoreId": {},
                "roleArn": {},
                "status": {},
                "creationTime": {
                  "shape": "S1n"
                },
                "completionTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReferenceStores": {
      "http": {
        "requestUri": "/referencestores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "name": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "referenceStores"
        ],
        "members": {
          "nextToken": {},
          "referenceStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "id",
                "creationTime"
              ],
              "members": {
                "arn": {},
                "id": {},
                "name": {},
                "description": {},
                "sseConfig": {
                  "shape": "Sx"
                },
                "creationTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListReferences": {
      "http": {
        "requestUri": "/referencestore/{referenceStoreId}/references",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "referenceStoreId"
        ],
        "members": {
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "name": {},
              "md5": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "references"
        ],
        "members": {
          "nextToken": {},
          "references": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "referenceStoreId",
                "md5",
                "creationTime",
                "updateTime"
              ],
              "members": {
                "id": {},
                "arn": {},
                "referenceStoreId": {},
                "md5": {},
                "status": {},
                "name": {},
                "description": {},
                "creationTime": {
                  "shape": "S1n"
                },
                "updateTime": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListRunGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/runGroup",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "startingToken": {
            "location": "querystring",
            "locationName": "startingToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "id": {},
                "name": {},
                "maxCpus": {
                  "type": "integer"
                },
                "maxRuns": {
                  "type": "integer"
                },
                "maxDuration": {
                  "type": "integer"
                },
                "creationTime": {
                  "shape": "S6m"
                },
                "maxGpus": {
                  "type": "integer"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "ListRunTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/run/{id}/task",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "startingToken": {
            "location": "querystring",
            "locationName": "startingToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "status": {},
                "name": {},
                "cpus": {
                  "type": "integer"
                },
                "memory": {
                  "type": "integer"
                },
                "creationTime": {
                  "shape": "S6v"
                },
                "startTime": {
                  "shape": "S6v"
                },
                "stopTime": {
                  "shape": "S6v"
                },
                "gpus": {
                  "type": "integer"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "ListRuns": {
      "http": {
        "method": "GET",
        "requestUri": "/run",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "runGroupId": {
            "location": "querystring",
            "locationName": "runGroupId"
          },
          "startingToken": {
            "location": "querystring",
            "locationName": "startingToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "id": {},
                "status": {},
                "workflowId": {},
                "name": {},
                "priority": {
                  "type": "integer"
                },
                "storageCapacity": {
                  "type": "integer"
                },
                "creationTime": {
                  "shape": "S6f"
                },
                "startTime": {
                  "shape": "S6f"
                },
                "stopTime": {
                  "shape": "S6f"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "ListSequenceStores": {
      "http": {
        "requestUri": "/sequencestores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "name": {},
              "createdAfter": {
                "shape": "S1n"
              },
              "createdBefore": {
                "shape": "S1n"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "sequenceStores"
        ],
        "members": {
          "nextToken": {},
          "sequenceStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "id",
                "creationTime"
              ],
              "members": {
                "arn": {},
                "id": {},
                "name": {},
                "description": {},
                "sseConfig": {
                  "shape": "Sx"
                },
                "creationTime": {
                  "shape": "S1n"
                },
                "fallbackLocation": {}
              }
            }
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "Su"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "tags-"
      }
    },
    "ListVariantImportJobs": {
      "http": {
        "requestUri": "/import/variants",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ids": {
            "type": "list",
            "member": {}
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {},
              "storeName": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "variantImportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "destinationName",
                "roleArn",
                "status",
                "creationTime",
                "updateTime"
              ],
              "members": {
                "id": {},
                "destinationName": {},
                "roleArn": {},
                "status": {},
                "creationTime": {
                  "shape": "S1d"
                },
                "updateTime": {
                  "shape": "S3h"
                },
                "completionTime": {
                  "shape": "S3i"
                },
                "runLeftNormalization": {
                  "type": "boolean"
                },
                "annotationFields": {
                  "shape": "S40"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "ListVariantStores": {
      "http": {
        "requestUri": "/variantStores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ids": {
            "type": "list",
            "member": {}
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "variantStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "reference",
                "status",
                "storeArn",
                "name",
                "description",
                "sseConfig",
                "creationTime",
                "updateTime",
                "statusMessage",
                "storeSizeBytes"
              ],
              "members": {
                "id": {},
                "reference": {
                  "shape": "Sq"
                },
                "status": {},
                "storeArn": {},
                "name": {},
                "description": {},
                "sseConfig": {
                  "shape": "Sx"
                },
                "creationTime": {
                  "shape": "S1d"
                },
                "updateTime": {
                  "shape": "S3h"
                },
                "statusMessage": {},
                "storeSizeBytes": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "ListWorkflows": {
      "http": {
        "method": "GET",
        "requestUri": "/workflow",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "type": {
            "location": "querystring",
            "locationName": "type"
          },
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "startingToken": {
            "location": "querystring",
            "locationName": "startingToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "id": {},
                "name": {},
                "status": {},
                "type": {},
                "digest": {},
                "creationTime": {
                  "shape": "S7c"
                },
                "metadata": {
                  "shape": "S7e"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "StartAnnotationImportJob": {
      "http": {
        "requestUri": "/import/annotation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "destinationName",
          "roleArn",
          "items"
        ],
        "members": {
          "destinationName": {},
          "roleArn": {},
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "source"
              ],
              "members": {
                "source": {}
              }
            }
          },
          "formatOptions": {
            "shape": "S3n"
          },
          "runLeftNormalization": {
            "type": "boolean"
          },
          "annotationFields": {
            "shape": "S40"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "StartReadSetActivationJob": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/activationjob",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "sources"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "clientToken": {},
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "readSetId"
              ],
              "members": {
                "readSetId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "status",
          "creationTime"
        ],
        "members": {
          "id": {},
          "sequenceStoreId": {},
          "status": {},
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "StartReadSetExportJob": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/exportjob",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "destination",
          "roleArn",
          "sources"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "destination": {},
          "roleArn": {},
          "clientToken": {},
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "readSetId"
              ],
              "members": {
                "readSetId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "destination",
          "status",
          "creationTime"
        ],
        "members": {
          "id": {},
          "sequenceStoreId": {},
          "destination": {},
          "status": {},
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "StartReadSetImportJob": {
      "http": {
        "requestUri": "/sequencestore/{sequenceStoreId}/importjob",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "roleArn",
          "sources"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "roleArn": {},
          "clientToken": {},
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sourceFiles",
                "sourceFileType",
                "subjectId",
                "sampleId",
                "referenceArn"
              ],
              "members": {
                "sourceFiles": {
                  "shape": "S4y"
                },
                "sourceFileType": {},
                "subjectId": {},
                "sampleId": {},
                "generatedFrom": {},
                "referenceArn": {},
                "name": {},
                "description": {},
                "tags": {
                  "shape": "Su"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "sequenceStoreId",
          "roleArn",
          "status",
          "creationTime"
        ],
        "members": {
          "id": {},
          "sequenceStoreId": {},
          "roleArn": {},
          "status": {},
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "StartReferenceImportJob": {
      "http": {
        "requestUri": "/referencestore/{referenceStoreId}/importjob",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "referenceStoreId",
          "roleArn",
          "sources"
        ],
        "members": {
          "referenceStoreId": {
            "location": "uri",
            "locationName": "referenceStoreId"
          },
          "roleArn": {},
          "clientToken": {},
          "sources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sourceFile",
                "name"
              ],
              "members": {
                "sourceFile": {},
                "name": {},
                "description": {},
                "tags": {
                  "shape": "Su"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "referenceStoreId",
          "roleArn",
          "status",
          "creationTime"
        ],
        "members": {
          "id": {},
          "referenceStoreId": {},
          "roleArn": {},
          "status": {},
          "creationTime": {
            "shape": "S1n"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "control-storage-"
      }
    },
    "StartRun": {
      "http": {
        "requestUri": "/run",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "roleArn",
          "requestId"
        ],
        "members": {
          "workflowId": {},
          "workflowType": {},
          "runId": {},
          "roleArn": {},
          "name": {},
          "runGroupId": {},
          "priority": {
            "type": "integer"
          },
          "parameters": {
            "shape": "S67"
          },
          "storageCapacity": {
            "type": "integer"
          },
          "outputUri": {},
          "logLevel": {},
          "tags": {
            "shape": "Su"
          },
          "requestId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "status": {},
          "tags": {
            "shape": "Su"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "StartVariantImportJob": {
      "http": {
        "requestUri": "/import/variant",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "destinationName",
          "roleArn",
          "items"
        ],
        "members": {
          "destinationName": {},
          "roleArn": {},
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "source"
              ],
              "members": {
                "source": {}
              }
            }
          },
          "runLeftNormalization": {
            "type": "boolean"
          },
          "annotationFields": {
            "shape": "S40"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "tags-"
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "tags-"
      },
      "idempotent": true
    },
    "UpdateAnnotationStore": {
      "http": {
        "requestUri": "/annotationStore/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "reference",
          "status",
          "name",
          "description",
          "creationTime",
          "updateTime"
        ],
        "members": {
          "id": {},
          "reference": {
            "shape": "Sq"
          },
          "status": {},
          "name": {},
          "description": {},
          "creationTime": {
            "shape": "S1d"
          },
          "updateTime": {
            "shape": "S3h"
          },
          "storeOptions": {
            "shape": "S11"
          },
          "storeFormat": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "UpdateRunGroup": {
      "http": {
        "requestUri": "/runGroup/{id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "name": {},
          "maxCpus": {
            "type": "integer"
          },
          "maxRuns": {
            "type": "integer"
          },
          "maxDuration": {
            "type": "integer"
          },
          "maxGpus": {
            "type": "integer"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "UpdateVariantStore": {
      "http": {
        "requestUri": "/variantStore/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "reference",
          "status",
          "name",
          "description",
          "creationTime",
          "updateTime"
        ],
        "members": {
          "id": {},
          "reference": {
            "shape": "Sq"
          },
          "status": {},
          "name": {},
          "description": {},
          "creationTime": {
            "shape": "S1d"
          },
          "updateTime": {
            "shape": "S3h"
          }
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "analytics-"
      }
    },
    "UpdateWorkflow": {
      "http": {
        "requestUri": "/workflow/{id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "name": {},
          "description": {}
        }
      },
      "authtype": "v4",
      "endpoint": {
        "hostPrefix": "workflows-"
      }
    },
    "UploadReadSetPart": {
      "http": {
        "method": "PUT",
        "requestUri": "/sequencestore/{sequenceStoreId}/upload/{uploadId}/part",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sequenceStoreId",
          "uploadId",
          "partSource",
          "partNumber",
          "payload"
        ],
        "members": {
          "sequenceStoreId": {
            "location": "uri",
            "locationName": "sequenceStoreId"
          },
          "uploadId": {
            "location": "uri",
            "locationName": "uploadId"
          },
          "partSource": {
            "location": "querystring",
            "locationName": "partSource"
          },
          "partNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          },
          "payload": {
            "type": "blob",
            "requiresLength": true,
            "streaming": true
          }
        },
        "payload": "payload"
      },
      "output": {
        "type": "structure",
        "required": [
          "checksum"
        ],
        "members": {
          "checksum": {}
        }
      },
      "authtype": "v4-unsigned-body",
      "endpoint": {
        "hostPrefix": "storage-"
      }
    }
  },
  "shapes": {
    "Sq": {
      "type": "structure",
      "members": {
        "referenceArn": {}
      },
      "union": true
    },
    "Su": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sx": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "keyArn": {}
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "tsvStoreOptions": {
          "type": "structure",
          "members": {
            "annotationType": {},
            "formatToHeader": {
              "type": "map",
              "key": {},
              "value": {}
            },
            "schema": {
              "type": "list",
              "member": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      },
      "union": true
    },
    "S1d": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S1n": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S2k": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "description": {},
          "optional": {
            "type": "boolean"
          }
        }
      }
    },
    "S3h": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S3i": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S3n": {
      "type": "structure",
      "members": {
        "tsvOptions": {
          "type": "structure",
          "members": {
            "readOptions": {
              "type": "structure",
              "members": {
                "sep": {},
                "encoding": {},
                "quote": {},
                "quoteAll": {
                  "type": "boolean"
                },
                "escape": {},
                "escapeQuotes": {
                  "type": "boolean"
                },
                "comment": {},
                "header": {
                  "type": "boolean"
                },
                "lineSep": {}
              }
            }
          }
        },
        "vcfOptions": {
          "type": "structure",
          "members": {
            "ignoreQualField": {
              "type": "boolean"
            },
            "ignoreFilterField": {
              "type": "boolean"
            }
          }
        }
      },
      "union": true
    },
    "S40": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S4y": {
      "type": "structure",
      "required": [
        "source1"
      ],
      "members": {
        "source1": {},
        "source2": {}
      }
    },
    "S54": {
      "type": "structure",
      "members": {
        "totalReadCount": {
          "type": "long"
        },
        "totalBaseCount": {
          "type": "long"
        },
        "generatedFrom": {},
        "alignment": {}
      }
    },
    "S56": {
      "type": "structure",
      "members": {
        "totalParts": {
          "type": "integer"
        },
        "partSize": {
          "type": "long"
        },
        "contentLength": {
          "type": "long"
        }
      }
    },
    "S67": {
      "type": "structure",
      "members": {},
      "document": true
    },
    "S6f": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S6m": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S6v": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S7c": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S7e": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}