import {Amplify, Auth, API, Cache} from 'aws-amplify';
import awsconfig from './aws-config.json';

import $ from 'jquery';
import AWS from 'aws-sdk';

import './css/root.css';

import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

var bootstrap = require('bootstrap');
import './modules/bootstrap-show-toast'

import "./css/datatables.css";
import "./css/main.css";
import "./css/query-builder.css";


Cache.setItem('lh', location.host);


export async function init(){

    let lh = Cache.getItem('lh');

    let apiconfig = awsconfig;

    API.configure({API: apiconfig.API});
    
    if (lh == "sheetloom.net") {
        window.location = "https://app.sheetloom.net";
    }

    if ( ['localhost', "dev", "app"].some((word) => lh.startsWith(word)) ) {
        let company_name = "dev4-ireland-4";
        Cache.setItem('company_name', company_name);
    }
    else {
        let company_name = lh.split(".")[0];
        Cache.setItem('company_name', company_name);
    }

    let company_name = Cache.getItem('company_name');

    let apienv = await getAPIEnv(company_name, lh);
    console.log(apienv);

    apiconfig.API.endpoints.forEach(element => {
        element.endpoint = element.endpoint.replace('{ENV}', apienv);
    });

    console.debug(apiconfig.API);

    let tempconfig = await getCompanyData(apiconfig, company_name);
    
    console.log(lh);
    if ( !(['localhost', "dev", "app"].some((word) => lh.startsWith(word))) && lh.endsWith(".sheetloom.net")) {
        console.debug("IS A SHEETLOOM URL!!!");
        if (company_name != null || company_name != "") {
            console.debug("IS A SHEETLOOM COMPANY!!!");
            tempconfig.oauth.redirectSignIn = "https://" + company_name + ".sheetloom.net";
            tempconfig.oauth.redirectSignOut = "https://" + company_name + ".sheetloom.net";
        }
        else{
            console.debug("NOT A SHEETLOOM COMPANY!!!");
            window.location = "https://sheetloom.com";
        }
    }
    else if (lh == "app.sheetloom.net" || lh == "dev.sheetloom.net") {
        tempconfig.oauth.redirectSignIn = "https://" + lh;
        tempconfig.oauth.redirectSignOut = "https://" + lh;
    }
    else if (lh.startsWith("localhost")){
        tempconfig.oauth.redirectSignIn = "http://" + lh;
        tempconfig.oauth.redirectSignOut = "http://" + lh;
    }
    else{
        window.location = "https://sheetloom.com";
    }

    Amplify.configure(tempconfig);
    Auth.configure({ oauth: tempconfig.oauth });

    Cache.setItem('sheetloom_bucket', company_name + '-sheetloom-templates');

    let params = {};
    let authtoken = "";
    try 
    {
        const authuser = await Auth.currentAuthenticatedUser();
        let leRole = await authuser.attributes['custom:role'];
        Cache.setItem('user_role', leRole);
        if (!(['admin', "user"].some((word) => leRole==word))) {
            alert("YOU DO NOT HAVE A USER OR ADMIN ROLE ASSIGNED TO YOUR USER! PLEASE SEE AN ADMINISTRATOR!");
            await Auth.signOut();
            window.localStorage.clear();
        }
        authtoken = authuser.signInUserSession.idToken.jwtToken;
        Cache.setItem('tokenExpiry', authuser.signInUserSession.idToken.payload.exp);
        headers['Authorization'] = authtoken;
        await Auth.currentCredentials().then(creds => AWS.config.credentials = creds);
        AWS.config.region = Cache.getItem('region');
        console.debug(authuser);
        console.debug(AWS.config);
        Cache.setItem('idToken', authtoken);
        Cache.setItem('email', authuser.attributes['email']);
        Cache.setItem('authusername', authuser.attributes['sub']);
        await getNav(leRole, tempconfig.page_list);
        // $("body").css({"visibility": "visible"});
        $("body").removeClass("setup");
    } 
    catch (error)
    {
        console.log(error);
        window.localStorage.clear();
        await Auth.federatedSignIn();
    }

    var sts = new AWS.STS();
    sts.getCallerIdentity({}, function(err, data) {
    if (err) {
        console.error("Error", err);
    } else {
        console.debug(JSON.stringify(data));
    }
    });

    params.company_name = company_name;
    params.authtoken = authtoken;
    params.tier = tempconfig.sheetloomTier

    return params;
}

global.headers = {
    'Authorization': '',
    "Accept": '*/*',
    "Content-Type": "application/json"
}


export async function signOut() {
    $("body").addClass("setup");
    await Auth.signOut();
    window.localStorage.clear();
}

window.setInterval(async function() {    
    const expiryTime = Cache.getItem('tokenExpiry');
    let isTokenExpired = Math.floor(new Date() / 1000) > expiryTime;
    //console.log(expiryTime + " == " + Math.floor(new Date() / 1000));
    if (isTokenExpired) {
        signOut();
    }
}, 5000)

window.setInterval(async function() {
    const account_number = Cache.getItem('account_number');
    const company_name = Cache.getItem('company_name');
    const authusername = Cache.getItem('authusername');
    const region = Cache.getItem('region');
    const sheetloom_bucket = Cache.getItem('sheetloom_bucket');

    //console.debug(account_number, company_name, authusername, region, sheetloom_bucket);

    try{
        readFromSQS(account_number, company_name, authusername, region, sheetloom_bucket);
    } catch(err) {
        console.error("Error reading from SQS: " + err)
    }
}, 8000)

// read from the users sqs queue. it has messages saying gen'd sheets are ready. downloads the sheet and deletes the message
async function readFromSQS(account_number, company_name, authusername, region, sheetloom_bucket){
    var sqs = new AWS.SQS({apiVersion: '2012-11-05'});
    let queueURL = "https://sqs." + region + ".amazonaws.com/"+account_number+"/"+company_name+"-"+authusername+"-sheetloom-queue";
    //let queueURL = "https://sqs.eu-west-1.amazonaws.com/383060934358/dev4-ireland-4-d0e4195a-4d33-44be-95d0-c772a28b3d91-sheetloom-queue"
    var receiveMessageParams = {
        AttributeNames: [
            "SentTimestamp"
        ],
        MaxNumberOfMessages: 10,
        MessageAttributeNames: [
            "All"
        ],
        QueueUrl: queueURL,
        VisibilityTimeout: 8,
        WaitTimeSeconds: 8
    };
    
    // read from queue
    sqs.receiveMessage(receiveMessageParams, async function(err, data) {
        if (err) {
            console.debug("Receive Error", err);
        } else if (data.Messages) {
            // go through all messages
            for (let i = 0; i < data.Messages.length; i++) {
                try{
                    const message = data.Messages[i];
                    let colour = "green"
                    try{colour = message.MessageAttributes.colour.StringValue}catch(e){}
                    console.log(colour);
                    let file = ""
                    try{file = message.MessageAttributes.file.StringValue}catch(e){}
                    console.log("body: ", message.Body)
                    
                    // delete from sqs queue
                    var deleteParams = {
                        QueueUrl: queueURL,
                        ReceiptHandle: message.ReceiptHandle
                    };
                    sqs.deleteMessage(deleteParams, function(err, data) {
                        if (err) {
                            console.log("Delete Error", err);
                        } else {
                            console.log("Message Deleted", data);
                        }
                    });
                    
                    // show alert 
                    if (colour == "green"){
                        // insert download link for the spreadsheet from the sqs message
                        if(file.includes('.')){
                            let toastmessage = file.split("/")[file.split("/").length-1] + ': <a id="' + file + '" style="color:white;" name="'+file+'" class="weave-download alert-link">Download</a>';
                            bootstrap.showToast({
                                body: toastmessage, toastClass: "toast-sheetloom", closeButtonClass: "btn-close-white", delay: Infinity, position: "bottom-0 end-0",
                            });
                            
                            $('.weave-download').on("click", function() {
                                let this_file = $(this).attr("id");
                                console.log(this_file);
                                weaveDownload(this_file);
                            })
                        }
                        else{
                            let toastmessage = message.Body;
                            bootstrap.showToast({
                                body: toastmessage, toastClass: "toast-sheetloom", closeButtonClass: "btn-close-white", delay: Infinity, position: "bottom-0 end-0",
                            });
                        }
                    }
                    else if (colour == "blue"){
                        let toastmessage = message.Body;
                        if (!(toastmessage.includes("started"))) {
                            bootstrap.showToast({
                                body: toastmessage, toastClass: "toast-sheetloom", closeButtonClass: "btn-close-white", delay: Infinity, position: "bottom-0 end-0",
                            });
                        } 
                    }
                    // error
                    else if (colour == "red"){
                        let toastmessage = message.Body;
                        bootstrap.showToast({
                            body: toastmessage, toastClass: "toast-sheetloom", closeButtonClass: "btn-close-white", delay: Infinity, position: "bottom-0 end-0",
                        });
                    }
                    // setTimeout(() => { parent.window.scrollTo(0, 0) }, 300);
                    
                }
                catch(e){
                    console.debug("Could not read SQS message: ", data.Messages[i])
                    console.debug(e)
                }
            }
        } 
    });
}

async function getNav(role, pages){
    await $.get("./nav.html", function(data){
        $("#import-nav").replaceWith(data);
        $('a.active').removeClass('active').removeAttr('aria-current');
        if (location.pathname == "/") {
            $('#explorerNavBtn').addClass('active').attr('aria-current', 'page');
        }
        else{
            console.debug(location);
            $('a[href=".' + location.pathname + location.search + '"]').addClass('active').attr('aria-current', 'page');
        }
    });
    // show full nav if admin
    if(role == "admin"){
        $('.users-hide').removeClass('users-hide');
    }

    pages = JSON.parse(pages);
    //console.log(pages);
    
    $("#menu").children("a").each(function(index, element) {
        let elementID = $(element)[0].id;
        if (elementID != "") {
            //console.log('Element ID:', elementID);
            const valueToCheck = elementID.replace("NavBtn", "");
            const isPartOfArray = pages.some(element => element.includes(valueToCheck));
            //console.log(isPartOfArray);
            if (isPartOfArray) {
                $(element).addClass("users-hide");
            }
        }
    });

    showNavbar("header-toggle", "nav-bar", "body-pd", "header", "logodisplay");
  
    if (Cache.getItem("navbar-status") == "open" && $(window).width() >= 768) {
        $("#header-toggle").trigger("click");
    }

    /*===== LINK ACTIVE =====*/
    const linkColor = document.querySelectorAll(".nav_link");

    function colorLink() {
        if (linkColor) {
            linkColor.forEach((l) => l.classList.remove("active"));
            this.classList.add("active");
        }
    }
      linkColor.forEach((l) => l.addEventListener("click", colorLink));

    if (pages.includes("patterns", "parameters", "connections")) {
        $("#patternManager").addClass("users-hide");
    }

    $("#signOutButton").on("click", function(event) {
        signOut();
    });
}



const showNavbar = (toggleId, navId, bodyId, headerId, logodisplay) => {
    const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId),
      bodypd = document.getElementById(bodyId),
      headerpd = document.getElementById(headerId);
      logodisplay = document.getElementById(logodisplay);

    // Validate that all variables exist
    if (toggle && nav && bodypd && headerpd && logodisplay) {
        toggle.addEventListener("click", () => {
            // show navbar
            nav.classList.toggle("show");
            // change icon
            toggle.classList.toggle("bx-x");
            // add padding to body
            bodypd.classList.toggle("body-pd");
            // add padding to header
            headerpd.classList.toggle("body-pd");

            console.log(logodisplay);

            // swap logos based on screen width
            if ($(window).width() >= 768) {
                if (logodisplay.classList.contains("logo-small")) {
                    bigLogo(logodisplay);
                };
                if (!(bodypd.classList.contains("body-pd"))){
                    smallLogo(logodisplay);
                }
            }
            else{
                smallLogo(logodisplay);
            }

            if ($("body").hasClass("body-pd")) {
                Cache.setItem("navbar-status", "open");
            }
            else if (!($("body").hasClass("body-pd"))) {
                Cache.setItem("navbar-status", "closed");
            }
        });
    }
};

function bigLogo(logo){
    logo.src = "assets/sheetloom-white.png";
    setTimeout(function(){ 
        logo.classList.add("logo-large");
        logo.classList.remove("logo-small");
    }, 10);
    
}

function smallLogo(logo){
    logo.src = "assets/sheetloom-logo-only.png";
    logo.classList.add("logo-small");
    logo.classList.remove("logo-large");
}

async function getCompanyData(temp, company_name) {
    let user_ip = await getCurrentIP();
    //console.log("User IP: " + user_ip);
    let companyBody = {
        body: {"company_name": company_name, "user_ip": user_ip, "site_page": window.location.pathname.split("/")[1]}
    };
    await API.post('core', '/tenant-info', companyBody)
    .then((response) => {
        let region = response.data[0].identity_pool_id.split(":")[0];
        temp.region = region;
        temp.identityPoolRegion = region;
        temp.identityPoolId = response.data[0].identity_pool_id
        temp.userPoolId = region + "_" + response.data[0].user_pool_id;
        temp.userPoolWebClientId = response.data[0].app_id;
        temp.oauth.domain = response.data[0].login_url;
        temp.page_list = response.data[0].page_list;
        Cache.setItem('account_number', response.data[0].account_number);
        Cache.setItem("region", region);
        temp.sheetloomTier = response.data[0].tier;
        console.debug(temp);
    })
    .catch((error) => {
        console.error(error.response.data.error);
        if (error.response.data.error == "Your IP is not authorised to access this site!") {
            alert("Access Denied. Your IP - " + user_ip + " - is not authorised to access this site!");
            window.location = "https://sheetloom.com";
        }
        else if (error.response.data.error == "Not allowed to access this page!") {
            alert("Access Denied. " + error.response.data.error);
            window.location = "/";
        }
        else{
            alert("Access Denied!");
            //window.location = "https://sheetloom.com";
        }
    });
    return temp;
}

async function getAPIEnv(company_name, lh) {
    let apienv = null;
    let companyBody = {
        body: {"company_name": company_name, "location_host": lh}
    };
    await API.post('getenv', '/', companyBody)
    .then((response) => {
        apienv = response.data[0].env;
        console.log(apienv);
    })
    .catch((error) => {
        console.error(error);
    });
    return apienv
}

async function getCurrentIP() {
    let user_ip = await fetch("https://api64.ipify.org?format=json")
        .then(response => response.json())
        .then(data => {
            let temp_user_ip = data.ip;
            //console.log("User IP address:", temp_user_ip);
            return temp_user_ip;
        })
        .catch(error => {
            console.error("Error fetching IP address:", error);
            return null;
        });
    return user_ip;
}

async function weaveDownload(filePath) {
    let s3 = new AWS.S3(AWS.config);
    let fileName = filePath.split("/")[filePath.split("/").length - 1];

    const bucketName = Cache.getItem('sheetloom_bucket');
    const objectKey = filePath;
    const params = {
        Bucket: bucketName,
        Key: objectKey
    };

    try {
        let response = await s3.headObject(params).promise();
        const tmp = response.LastModified;
        
        // Get the current date and time
        var currentDate = new Date(tmp);

        // Extract date components
        var year = currentDate.getFullYear();
        var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        var day = String(currentDate.getDate()).padStart(2, '0');

        // Extract time components
        var hours = String(currentDate.getHours()).padStart(2, '0');
        var minutes = String(currentDate.getMinutes()).padStart(2, '0');
        var seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Format the current date and time in the desired way
        var formattedDate = year + month + day + '_' + hours + minutes + seconds;

        console.log(formattedDate);


        let url = "";
        if(fileName.includes(".xlsx")) {
            url = s3.getSignedUrl('getObject', {
                Bucket: Cache.getItem('sheetloom_bucket'),
                Key: filePath,
                Expires: 60 * 5,
                ResponseContentDisposition: 'attachment; filename ="' + fileName.split(".xlsx")[0] + "_sheet_" + formattedDate + '.xlsx"'
            });
        } else {
            url = s3.getSignedUrl('getObject', {
                Bucket: Cache.getItem('sheetloom_bucket'),
                Key: filePath,
                Expires: 60 * 5
            });
        }
        console.log(url);
        window.open(url, '_blank');
        return url;
    } catch (error) {
        console.error(error);
        return null;
    }
}

$( window ).on( "resize", function() {
    logodisplay = document.getElementById("logodisplay");
    bodypd = document.getElementById("body-pd")
    if ($(window).width() < 768) {
        smallLogo(logodisplay);
    }
    else{
        if (bodypd.classList.contains("body-pd")){
            bigLogo(logodisplay);
        }
    }
});