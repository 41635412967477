{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-15",
    "endpointPrefix": "backup",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Backup",
    "serviceId": "Backup",
    "signatureVersion": "v4",
    "uid": "backup-2018-11-15"
  },
  "operations": {
    "CancelLegalHold": {
      "http": {
        "method": "DELETE",
        "requestUri": "/legal-holds/{legalHoldId}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LegalHoldId",
          "CancelDescription"
        ],
        "members": {
          "LegalHoldId": {
            "location": "uri",
            "locationName": "legalHoldId"
          },
          "CancelDescription": {
            "location": "querystring",
            "locationName": "cancelDescription"
          },
          "RetainRecordInDays": {
            "location": "querystring",
            "locationName": "retainRecordInDays",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateBackupPlan": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup/plans/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlan"
        ],
        "members": {
          "BackupPlan": {
            "shape": "S6"
          },
          "BackupPlanTags": {
            "shape": "Sf"
          },
          "CreatorRequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlanId": {},
          "BackupPlanArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "VersionId": {},
          "AdvancedBackupSettings": {
            "shape": "Sm"
          }
        }
      },
      "idempotent": true
    },
    "CreateBackupSelection": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup/plans/{backupPlanId}/selections/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId",
          "BackupSelection"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "BackupSelection": {
            "shape": "Sv"
          },
          "CreatorRequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SelectionId": {},
          "BackupPlanId": {},
          "CreationDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "CreateBackupVault": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup-vaults/{backupVaultName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "BackupVaultTags": {
            "shape": "Sf"
          },
          "EncryptionKeyArn": {},
          "CreatorRequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultName": {},
          "BackupVaultArn": {},
          "CreationDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "CreateFramework": {
      "http": {
        "requestUri": "/audit/frameworks"
      },
      "input": {
        "type": "structure",
        "required": [
          "FrameworkName",
          "FrameworkControls"
        ],
        "members": {
          "FrameworkName": {},
          "FrameworkDescription": {},
          "FrameworkControls": {
            "shape": "S1d"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          },
          "FrameworkTags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FrameworkName": {},
          "FrameworkArn": {}
        }
      },
      "idempotent": true
    },
    "CreateLegalHold": {
      "http": {
        "requestUri": "/legal-holds/"
      },
      "input": {
        "type": "structure",
        "required": [
          "Title",
          "Description"
        ],
        "members": {
          "Title": {},
          "Description": {},
          "IdempotencyToken": {},
          "RecoveryPointSelection": {
            "shape": "S1q"
          },
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Title": {},
          "Status": {},
          "Description": {},
          "LegalHoldId": {},
          "LegalHoldArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "RecoveryPointSelection": {
            "shape": "S1q"
          }
        }
      },
      "idempotent": true
    },
    "CreateReportPlan": {
      "http": {
        "requestUri": "/audit/report-plans"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReportPlanName",
          "ReportDeliveryChannel",
          "ReportSetting"
        ],
        "members": {
          "ReportPlanName": {},
          "ReportPlanDescription": {},
          "ReportDeliveryChannel": {
            "shape": "S1z"
          },
          "ReportSetting": {
            "shape": "S21"
          },
          "ReportPlanTags": {
            "shape": "S1n"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportPlanName": {},
          "ReportPlanArn": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DeleteBackupPlan": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup/plans/{backupPlanId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlanId": {},
          "BackupPlanArn": {},
          "DeletionDate": {
            "type": "timestamp"
          },
          "VersionId": {}
        }
      }
    },
    "DeleteBackupSelection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup/plans/{backupPlanId}/selections/{selectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId",
          "SelectionId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "SelectionId": {
            "location": "uri",
            "locationName": "selectionId"
          }
        }
      }
    },
    "DeleteBackupVault": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup-vaults/{backupVaultName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      }
    },
    "DeleteBackupVaultAccessPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup-vaults/{backupVaultName}/access-policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      },
      "idempotent": true
    },
    "DeleteBackupVaultLockConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup-vaults/{backupVaultName}/vault-lock"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      },
      "idempotent": true
    },
    "DeleteBackupVaultNotifications": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup-vaults/{backupVaultName}/notification-configuration"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      },
      "idempotent": true
    },
    "DeleteFramework": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/frameworks/{frameworkName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FrameworkName"
        ],
        "members": {
          "FrameworkName": {
            "location": "uri",
            "locationName": "frameworkName"
          }
        }
      }
    },
    "DeleteRecoveryPoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/{recoveryPointArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "RecoveryPointArn"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "RecoveryPointArn": {
            "location": "uri",
            "locationName": "recoveryPointArn"
          }
        }
      },
      "idempotent": true
    },
    "DeleteReportPlan": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/report-plans/{reportPlanName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReportPlanName"
        ],
        "members": {
          "ReportPlanName": {
            "location": "uri",
            "locationName": "reportPlanName"
          }
        }
      },
      "idempotent": true
    },
    "DescribeBackupJob": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-jobs/{backupJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupJobId"
        ],
        "members": {
          "BackupJobId": {
            "location": "uri",
            "locationName": "backupJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "BackupJobId": {},
          "BackupVaultName": {},
          "BackupVaultArn": {},
          "RecoveryPointArn": {},
          "ResourceArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "CompletionDate": {
            "type": "timestamp"
          },
          "State": {},
          "StatusMessage": {},
          "PercentDone": {},
          "BackupSizeInBytes": {
            "type": "long"
          },
          "IamRoleArn": {},
          "CreatedBy": {
            "shape": "S2j"
          },
          "ResourceType": {},
          "BytesTransferred": {
            "type": "long"
          },
          "ExpectedCompletionDate": {
            "type": "timestamp"
          },
          "StartBy": {
            "type": "timestamp"
          },
          "BackupOptions": {
            "shape": "Sp"
          },
          "BackupType": {},
          "ParentJobId": {},
          "IsParent": {
            "type": "boolean"
          },
          "NumberOfChildJobs": {
            "type": "long"
          },
          "ChildJobsInState": {
            "type": "map",
            "key": {},
            "value": {
              "type": "long"
            }
          },
          "ResourceName": {}
        }
      },
      "idempotent": true
    },
    "DescribeBackupVault": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/{backupVaultName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultName": {},
          "BackupVaultArn": {},
          "EncryptionKeyArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "CreatorRequestId": {},
          "NumberOfRecoveryPoints": {
            "type": "long"
          },
          "Locked": {
            "type": "boolean"
          },
          "MinRetentionDays": {
            "type": "long"
          },
          "MaxRetentionDays": {
            "type": "long"
          },
          "LockDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeCopyJob": {
      "http": {
        "method": "GET",
        "requestUri": "/copy-jobs/{copyJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "CopyJobId"
        ],
        "members": {
          "CopyJobId": {
            "location": "uri",
            "locationName": "copyJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyJob": {
            "shape": "S2r"
          }
        }
      },
      "idempotent": true
    },
    "DescribeFramework": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/frameworks/{frameworkName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FrameworkName"
        ],
        "members": {
          "FrameworkName": {
            "location": "uri",
            "locationName": "frameworkName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FrameworkName": {},
          "FrameworkArn": {},
          "FrameworkDescription": {},
          "FrameworkControls": {
            "shape": "S1d"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "DeploymentStatus": {},
          "FrameworkStatus": {},
          "IdempotencyToken": {}
        }
      }
    },
    "DescribeGlobalSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/global-settings"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalSettings": {
            "shape": "S2y"
          },
          "LastUpdateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeProtectedResource": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "ResourceType": {},
          "LastBackupTime": {
            "type": "timestamp"
          },
          "ResourceName": {}
        }
      },
      "idempotent": true
    },
    "DescribeRecoveryPoint": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/{recoveryPointArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "RecoveryPointArn"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "RecoveryPointArn": {
            "location": "uri",
            "locationName": "recoveryPointArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecoveryPointArn": {},
          "BackupVaultName": {},
          "BackupVaultArn": {},
          "SourceBackupVaultArn": {},
          "ResourceArn": {},
          "ResourceType": {},
          "CreatedBy": {
            "shape": "S2j"
          },
          "IamRoleArn": {},
          "Status": {},
          "StatusMessage": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "CompletionDate": {
            "type": "timestamp"
          },
          "BackupSizeInBytes": {
            "type": "long"
          },
          "CalculatedLifecycle": {
            "shape": "S36"
          },
          "Lifecycle": {
            "shape": "Se"
          },
          "EncryptionKeyArn": {},
          "IsEncrypted": {
            "type": "boolean"
          },
          "StorageClass": {},
          "LastRestoreTime": {
            "type": "timestamp"
          },
          "ParentRecoveryPointArn": {},
          "CompositeMemberIdentifier": {},
          "IsParent": {
            "type": "boolean"
          },
          "ResourceName": {}
        }
      },
      "idempotent": true
    },
    "DescribeRegionSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/account-settings"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceTypeOptInPreference": {
            "shape": "S3a"
          },
          "ResourceTypeManagementPreference": {
            "shape": "S3c"
          }
        }
      }
    },
    "DescribeReportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/report-jobs/{reportJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReportJobId"
        ],
        "members": {
          "ReportJobId": {
            "location": "uri",
            "locationName": "reportJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportJob": {
            "shape": "S3g"
          }
        }
      }
    },
    "DescribeReportPlan": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/report-plans/{reportPlanName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReportPlanName"
        ],
        "members": {
          "ReportPlanName": {
            "location": "uri",
            "locationName": "reportPlanName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportPlan": {
            "shape": "S3k"
          }
        }
      }
    },
    "DescribeRestoreJob": {
      "http": {
        "method": "GET",
        "requestUri": "/restore-jobs/{restoreJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "RestoreJobId"
        ],
        "members": {
          "RestoreJobId": {
            "location": "uri",
            "locationName": "restoreJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "RestoreJobId": {},
          "RecoveryPointArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "CompletionDate": {
            "type": "timestamp"
          },
          "Status": {},
          "StatusMessage": {},
          "PercentDone": {},
          "BackupSizeInBytes": {
            "type": "long"
          },
          "IamRoleArn": {},
          "ExpectedCompletionTimeMinutes": {
            "type": "long"
          },
          "CreatedResourceArn": {},
          "ResourceType": {}
        }
      },
      "idempotent": true
    },
    "DisassociateRecoveryPoint": {
      "http": {
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/{recoveryPointArn}/disassociate"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "RecoveryPointArn"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "RecoveryPointArn": {
            "location": "uri",
            "locationName": "recoveryPointArn"
          }
        }
      }
    },
    "DisassociateRecoveryPointFromParent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/{recoveryPointArn}/parentAssociation",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "RecoveryPointArn"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "RecoveryPointArn": {
            "location": "uri",
            "locationName": "recoveryPointArn"
          }
        }
      }
    },
    "ExportBackupPlanTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/plans/{backupPlanId}/toTemplate/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlanTemplateJson": {}
        }
      }
    },
    "GetBackupPlan": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/plans/{backupPlanId}/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlan": {
            "shape": "S3v"
          },
          "BackupPlanId": {},
          "BackupPlanArn": {},
          "VersionId": {},
          "CreatorRequestId": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "DeletionDate": {
            "type": "timestamp"
          },
          "LastExecutionDate": {
            "type": "timestamp"
          },
          "AdvancedBackupSettings": {
            "shape": "Sm"
          }
        }
      },
      "idempotent": true
    },
    "GetBackupPlanFromJSON": {
      "http": {
        "requestUri": "/backup/template/json/toPlan"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanTemplateJson"
        ],
        "members": {
          "BackupPlanTemplateJson": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlan": {
            "shape": "S3v"
          }
        }
      }
    },
    "GetBackupPlanFromTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/template/plans/{templateId}/toPlan"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanTemplateId"
        ],
        "members": {
          "BackupPlanTemplateId": {
            "location": "uri",
            "locationName": "templateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlanDocument": {
            "shape": "S3v"
          }
        }
      }
    },
    "GetBackupSelection": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/plans/{backupPlanId}/selections/{selectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId",
          "SelectionId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "SelectionId": {
            "location": "uri",
            "locationName": "selectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupSelection": {
            "shape": "Sv"
          },
          "SelectionId": {},
          "BackupPlanId": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "CreatorRequestId": {}
        }
      },
      "idempotent": true
    },
    "GetBackupVaultAccessPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/{backupVaultName}/access-policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultName": {},
          "BackupVaultArn": {},
          "Policy": {}
        }
      },
      "idempotent": true
    },
    "GetBackupVaultNotifications": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/{backupVaultName}/notification-configuration"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultName": {},
          "BackupVaultArn": {},
          "SNSTopicArn": {},
          "BackupVaultEvents": {
            "shape": "S49"
          }
        }
      },
      "idempotent": true
    },
    "GetLegalHold": {
      "http": {
        "method": "GET",
        "requestUri": "/legal-holds/{legalHoldId}/"
      },
      "input": {
        "type": "structure",
        "required": [
          "LegalHoldId"
        ],
        "members": {
          "LegalHoldId": {
            "location": "uri",
            "locationName": "legalHoldId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Title": {},
          "Status": {},
          "Description": {},
          "CancelDescription": {},
          "LegalHoldId": {},
          "LegalHoldArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "CancellationDate": {
            "type": "timestamp"
          },
          "RetainRecordUntil": {
            "type": "timestamp"
          },
          "RecoveryPointSelection": {
            "shape": "S1q"
          }
        }
      },
      "idempotent": true
    },
    "GetRecoveryPointRestoreMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/{recoveryPointArn}/restore-metadata"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "RecoveryPointArn"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "RecoveryPointArn": {
            "location": "uri",
            "locationName": "recoveryPointArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultArn": {},
          "RecoveryPointArn": {},
          "RestoreMetadata": {
            "shape": "S4f"
          }
        }
      },
      "idempotent": true
    },
    "GetSupportedResourceTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/supported-resource-types"
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceTypes": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListBackupJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-jobs/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ByResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "ByState": {
            "location": "querystring",
            "locationName": "state"
          },
          "ByBackupVaultName": {
            "location": "querystring",
            "locationName": "backupVaultName"
          },
          "ByCreatedBefore": {
            "location": "querystring",
            "locationName": "createdBefore",
            "type": "timestamp"
          },
          "ByCreatedAfter": {
            "location": "querystring",
            "locationName": "createdAfter",
            "type": "timestamp"
          },
          "ByResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "ByAccountId": {
            "location": "querystring",
            "locationName": "accountId"
          },
          "ByCompleteAfter": {
            "location": "querystring",
            "locationName": "completeAfter",
            "type": "timestamp"
          },
          "ByCompleteBefore": {
            "location": "querystring",
            "locationName": "completeBefore",
            "type": "timestamp"
          },
          "ByParentJobId": {
            "location": "querystring",
            "locationName": "parentJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "BackupJobId": {},
                "BackupVaultName": {},
                "BackupVaultArn": {},
                "RecoveryPointArn": {},
                "ResourceArn": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "CompletionDate": {
                  "type": "timestamp"
                },
                "State": {},
                "StatusMessage": {},
                "PercentDone": {},
                "BackupSizeInBytes": {
                  "type": "long"
                },
                "IamRoleArn": {},
                "CreatedBy": {
                  "shape": "S2j"
                },
                "ExpectedCompletionDate": {
                  "type": "timestamp"
                },
                "StartBy": {
                  "type": "timestamp"
                },
                "ResourceType": {},
                "BytesTransferred": {
                  "type": "long"
                },
                "BackupOptions": {
                  "shape": "Sp"
                },
                "BackupType": {},
                "ParentJobId": {},
                "IsParent": {
                  "type": "boolean"
                },
                "ResourceName": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListBackupPlanTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/template/plans"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "BackupPlanTemplatesList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "BackupPlanTemplateId": {},
                "BackupPlanTemplateName": {}
              }
            }
          }
        }
      }
    },
    "ListBackupPlanVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/plans/{backupPlanId}/versions/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "BackupPlanVersionsList": {
            "type": "list",
            "member": {
              "shape": "S4w"
            }
          }
        }
      },
      "idempotent": true
    },
    "ListBackupPlans": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/plans/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "IncludeDeleted": {
            "location": "querystring",
            "locationName": "includeDeleted",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "BackupPlansList": {
            "type": "list",
            "member": {
              "shape": "S4w"
            }
          }
        }
      },
      "idempotent": true
    },
    "ListBackupSelections": {
      "http": {
        "method": "GET",
        "requestUri": "/backup/plans/{backupPlanId}/selections/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "BackupSelectionsList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SelectionId": {},
                "SelectionName": {},
                "BackupPlanId": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "CreatorRequestId": {},
                "IamRoleArn": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListBackupVaults": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "BackupVaultName": {},
                "BackupVaultArn": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "EncryptionKeyArn": {},
                "CreatorRequestId": {},
                "NumberOfRecoveryPoints": {
                  "type": "long"
                },
                "Locked": {
                  "type": "boolean"
                },
                "MinRetentionDays": {
                  "type": "long"
                },
                "MaxRetentionDays": {
                  "type": "long"
                },
                "LockDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListCopyJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/copy-jobs/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ByResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "ByState": {
            "location": "querystring",
            "locationName": "state"
          },
          "ByCreatedBefore": {
            "location": "querystring",
            "locationName": "createdBefore",
            "type": "timestamp"
          },
          "ByCreatedAfter": {
            "location": "querystring",
            "locationName": "createdAfter",
            "type": "timestamp"
          },
          "ByResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "ByDestinationVaultArn": {
            "location": "querystring",
            "locationName": "destinationVaultArn"
          },
          "ByAccountId": {
            "location": "querystring",
            "locationName": "accountId"
          },
          "ByCompleteBefore": {
            "location": "querystring",
            "locationName": "completeBefore",
            "type": "timestamp"
          },
          "ByCompleteAfter": {
            "location": "querystring",
            "locationName": "completeAfter",
            "type": "timestamp"
          },
          "ByParentJobId": {
            "location": "querystring",
            "locationName": "parentJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyJobs": {
            "type": "list",
            "member": {
              "shape": "S2r"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFrameworks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/frameworks"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Frameworks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FrameworkName": {},
                "FrameworkArn": {},
                "FrameworkDescription": {},
                "NumberOfControls": {
                  "type": "integer"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "DeploymentStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLegalHolds": {
      "http": {
        "method": "GET",
        "requestUri": "/legal-holds/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "LegalHolds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Title": {},
                "Status": {},
                "Description": {},
                "LegalHoldId": {},
                "LegalHoldArn": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "CancellationDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListProtectedResources": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceArn": {},
                "ResourceType": {},
                "LastBackupTime": {
                  "type": "timestamp"
                },
                "ResourceName": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListRecoveryPointsByBackupVault": {
      "http": {
        "method": "GET",
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ByResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "ByResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "ByBackupPlanId": {
            "location": "querystring",
            "locationName": "backupPlanId"
          },
          "ByCreatedBefore": {
            "location": "querystring",
            "locationName": "createdBefore",
            "type": "timestamp"
          },
          "ByCreatedAfter": {
            "location": "querystring",
            "locationName": "createdAfter",
            "type": "timestamp"
          },
          "ByParentRecoveryPointArn": {
            "location": "querystring",
            "locationName": "parentRecoveryPointArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RecoveryPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecoveryPointArn": {},
                "BackupVaultName": {},
                "BackupVaultArn": {},
                "SourceBackupVaultArn": {},
                "ResourceArn": {},
                "ResourceType": {},
                "CreatedBy": {
                  "shape": "S2j"
                },
                "IamRoleArn": {},
                "Status": {},
                "StatusMessage": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "CompletionDate": {
                  "type": "timestamp"
                },
                "BackupSizeInBytes": {
                  "type": "long"
                },
                "CalculatedLifecycle": {
                  "shape": "S36"
                },
                "Lifecycle": {
                  "shape": "Se"
                },
                "EncryptionKeyArn": {},
                "IsEncrypted": {
                  "type": "boolean"
                },
                "LastRestoreTime": {
                  "type": "timestamp"
                },
                "ParentRecoveryPointArn": {},
                "CompositeMemberIdentifier": {},
                "IsParent": {
                  "type": "boolean"
                },
                "ResourceName": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListRecoveryPointsByLegalHold": {
      "http": {
        "method": "GET",
        "requestUri": "/legal-holds/{legalHoldId}/recovery-points"
      },
      "input": {
        "type": "structure",
        "required": [
          "LegalHoldId"
        ],
        "members": {
          "LegalHoldId": {
            "location": "uri",
            "locationName": "legalHoldId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecoveryPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecoveryPointArn": {},
                "ResourceArn": {},
                "ResourceType": {},
                "BackupVaultName": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListRecoveryPointsByResource": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/{resourceArn}/recovery-points/"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RecoveryPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecoveryPointArn": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "Status": {},
                "StatusMessage": {},
                "EncryptionKeyArn": {},
                "BackupSizeBytes": {
                  "type": "long"
                },
                "BackupVaultName": {},
                "IsParent": {
                  "type": "boolean"
                },
                "ParentRecoveryPointArn": {},
                "ResourceName": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListReportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/report-jobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "ByReportPlanName": {
            "location": "querystring",
            "locationName": "ReportPlanName"
          },
          "ByCreationBefore": {
            "location": "querystring",
            "locationName": "CreationBefore",
            "type": "timestamp"
          },
          "ByCreationAfter": {
            "location": "querystring",
            "locationName": "CreationAfter",
            "type": "timestamp"
          },
          "ByStatus": {
            "location": "querystring",
            "locationName": "Status"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportJobs": {
            "type": "list",
            "member": {
              "shape": "S3g"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListReportPlans": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/report-plans"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportPlans": {
            "type": "list",
            "member": {
              "shape": "S3k"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRestoreJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/restore-jobs/"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ByAccountId": {
            "location": "querystring",
            "locationName": "accountId"
          },
          "ByCreatedBefore": {
            "location": "querystring",
            "locationName": "createdBefore",
            "type": "timestamp"
          },
          "ByCreatedAfter": {
            "location": "querystring",
            "locationName": "createdAfter",
            "type": "timestamp"
          },
          "ByStatus": {
            "location": "querystring",
            "locationName": "status"
          },
          "ByCompleteBefore": {
            "location": "querystring",
            "locationName": "completeBefore",
            "type": "timestamp"
          },
          "ByCompleteAfter": {
            "location": "querystring",
            "locationName": "completeAfter",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RestoreJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "RestoreJobId": {},
                "RecoveryPointArn": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "CompletionDate": {
                  "type": "timestamp"
                },
                "Status": {},
                "StatusMessage": {},
                "PercentDone": {},
                "BackupSizeInBytes": {
                  "type": "long"
                },
                "IamRoleArn": {},
                "ExpectedCompletionTimeMinutes": {
                  "type": "long"
                },
                "CreatedResourceArn": {},
                "ResourceType": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}/"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "idempotent": true
    },
    "PutBackupVaultAccessPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup-vaults/{backupVaultName}/access-policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "Policy": {}
        }
      },
      "idempotent": true
    },
    "PutBackupVaultLockConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup-vaults/{backupVaultName}/vault-lock"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "MinRetentionDays": {
            "type": "long"
          },
          "MaxRetentionDays": {
            "type": "long"
          },
          "ChangeableForDays": {
            "type": "long"
          }
        }
      },
      "idempotent": true
    },
    "PutBackupVaultNotifications": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup-vaults/{backupVaultName}/notification-configuration"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "SNSTopicArn",
          "BackupVaultEvents"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "SNSTopicArn": {},
          "BackupVaultEvents": {
            "shape": "S49"
          }
        }
      },
      "idempotent": true
    },
    "StartBackupJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/backup-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "ResourceArn",
          "IamRoleArn"
        ],
        "members": {
          "BackupVaultName": {},
          "ResourceArn": {},
          "IamRoleArn": {},
          "IdempotencyToken": {},
          "StartWindowMinutes": {
            "type": "long"
          },
          "CompleteWindowMinutes": {
            "type": "long"
          },
          "Lifecycle": {
            "shape": "Se"
          },
          "RecoveryPointTags": {
            "shape": "Sf"
          },
          "BackupOptions": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupJobId": {},
          "RecoveryPointArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "IsParent": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "StartCopyJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/copy-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "RecoveryPointArn",
          "SourceBackupVaultName",
          "DestinationBackupVaultArn",
          "IamRoleArn"
        ],
        "members": {
          "RecoveryPointArn": {},
          "SourceBackupVaultName": {},
          "DestinationBackupVaultArn": {},
          "IamRoleArn": {},
          "IdempotencyToken": {},
          "Lifecycle": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyJobId": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "IsParent": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "StartReportJob": {
      "http": {
        "requestUri": "/audit/report-jobs/{reportPlanName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReportPlanName"
        ],
        "members": {
          "ReportPlanName": {
            "location": "uri",
            "locationName": "reportPlanName"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportJobId": {}
        }
      },
      "idempotent": true
    },
    "StartRestoreJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/restore-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "RecoveryPointArn",
          "Metadata"
        ],
        "members": {
          "RecoveryPointArn": {},
          "Metadata": {
            "shape": "S4f"
          },
          "IamRoleArn": {},
          "IdempotencyToken": {},
          "ResourceType": {},
          "CopySourceTagsToRestoredResource": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RestoreJobId": {}
        }
      },
      "idempotent": true
    },
    "StopBackupJob": {
      "http": {
        "requestUri": "/backup-jobs/{backupJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupJobId"
        ],
        "members": {
          "BackupJobId": {
            "location": "uri",
            "locationName": "backupJobId"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "requestUri": "/untag/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeyList"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "TagKeyList": {
            "type": "list",
            "member": {},
            "sensitive": true
          }
        }
      },
      "idempotent": true
    },
    "UpdateBackupPlan": {
      "http": {
        "requestUri": "/backup/plans/{backupPlanId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupPlanId",
          "BackupPlan"
        ],
        "members": {
          "BackupPlanId": {
            "location": "uri",
            "locationName": "backupPlanId"
          },
          "BackupPlan": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupPlanId": {},
          "BackupPlanArn": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "VersionId": {},
          "AdvancedBackupSettings": {
            "shape": "Sm"
          }
        }
      },
      "idempotent": true
    },
    "UpdateFramework": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/frameworks/{frameworkName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FrameworkName"
        ],
        "members": {
          "FrameworkName": {
            "location": "uri",
            "locationName": "frameworkName"
          },
          "FrameworkDescription": {},
          "FrameworkControls": {
            "shape": "S1d"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FrameworkName": {},
          "FrameworkArn": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "UpdateGlobalSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/global-settings"
      },
      "input": {
        "type": "structure",
        "members": {
          "GlobalSettings": {
            "shape": "S2y"
          }
        }
      }
    },
    "UpdateRecoveryPointLifecycle": {
      "http": {
        "requestUri": "/backup-vaults/{backupVaultName}/recovery-points/{recoveryPointArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "BackupVaultName",
          "RecoveryPointArn"
        ],
        "members": {
          "BackupVaultName": {
            "location": "uri",
            "locationName": "backupVaultName"
          },
          "RecoveryPointArn": {
            "location": "uri",
            "locationName": "recoveryPointArn"
          },
          "Lifecycle": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupVaultArn": {},
          "RecoveryPointArn": {},
          "Lifecycle": {
            "shape": "Se"
          },
          "CalculatedLifecycle": {
            "shape": "S36"
          }
        }
      },
      "idempotent": true
    },
    "UpdateRegionSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/account-settings"
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceTypeOptInPreference": {
            "shape": "S3a"
          },
          "ResourceTypeManagementPreference": {
            "shape": "S3c"
          }
        }
      }
    },
    "UpdateReportPlan": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/report-plans/{reportPlanName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReportPlanName"
        ],
        "members": {
          "ReportPlanName": {
            "location": "uri",
            "locationName": "reportPlanName"
          },
          "ReportPlanDescription": {},
          "ReportDeliveryChannel": {
            "shape": "S1z"
          },
          "ReportSetting": {
            "shape": "S21"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportPlanName": {},
          "ReportPlanArn": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "required": [
        "BackupPlanName",
        "Rules"
      ],
      "members": {
        "BackupPlanName": {},
        "Rules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "RuleName",
              "TargetBackupVaultName"
            ],
            "members": {
              "RuleName": {},
              "TargetBackupVaultName": {},
              "ScheduleExpression": {},
              "StartWindowMinutes": {
                "type": "long"
              },
              "CompletionWindowMinutes": {
                "type": "long"
              },
              "Lifecycle": {
                "shape": "Se"
              },
              "RecoveryPointTags": {
                "shape": "Sf"
              },
              "CopyActions": {
                "shape": "Si"
              },
              "EnableContinuousBackup": {
                "type": "boolean"
              }
            }
          }
        },
        "AdvancedBackupSettings": {
          "shape": "Sm"
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "MoveToColdStorageAfterDays": {
          "type": "long"
        },
        "DeleteAfterDays": {
          "type": "long"
        }
      }
    },
    "Sf": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DestinationBackupVaultArn"
        ],
        "members": {
          "Lifecycle": {
            "shape": "Se"
          },
          "DestinationBackupVaultArn": {}
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "BackupOptions": {
            "shape": "Sp"
          }
        }
      }
    },
    "Sp": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sv": {
      "type": "structure",
      "required": [
        "SelectionName",
        "IamRoleArn"
      ],
      "members": {
        "SelectionName": {},
        "IamRoleArn": {},
        "Resources": {
          "shape": "Sy"
        },
        "ListOfTags": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ConditionType",
              "ConditionKey",
              "ConditionValue"
            ],
            "members": {
              "ConditionType": {},
              "ConditionKey": {},
              "ConditionValue": {}
            }
          }
        },
        "NotResources": {
          "shape": "Sy"
        },
        "Conditions": {
          "type": "structure",
          "members": {
            "StringEquals": {
              "shape": "S15"
            },
            "StringNotEquals": {
              "shape": "S15"
            },
            "StringLike": {
              "shape": "S15"
            },
            "StringNotLike": {
              "shape": "S15"
            }
          }
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {}
    },
    "S15": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ConditionKey": {},
          "ConditionValue": {}
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ControlName"
        ],
        "members": {
          "ControlName": {},
          "ControlInputParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterName": {},
                "ParameterValue": {}
              }
            }
          },
          "ControlScope": {
            "type": "structure",
            "members": {
              "ComplianceResourceIds": {
                "type": "list",
                "member": {}
              },
              "ComplianceResourceTypes": {
                "type": "list",
                "member": {}
              },
              "Tags": {
                "shape": "S1n"
              }
            }
          }
        }
      }
    },
    "S1n": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1q": {
      "type": "structure",
      "members": {
        "VaultNames": {
          "type": "list",
          "member": {}
        },
        "ResourceIdentifiers": {
          "type": "list",
          "member": {}
        },
        "DateRange": {
          "type": "structure",
          "required": [
            "FromDate",
            "ToDate"
          ],
          "members": {
            "FromDate": {
              "type": "timestamp"
            },
            "ToDate": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "required": [
        "S3BucketName"
      ],
      "members": {
        "S3BucketName": {},
        "S3KeyPrefix": {},
        "Formats": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S21": {
      "type": "structure",
      "required": [
        "ReportTemplate"
      ],
      "members": {
        "ReportTemplate": {},
        "FrameworkArns": {
          "shape": "S22"
        },
        "NumberOfFrameworks": {
          "type": "integer"
        },
        "Accounts": {
          "shape": "S22"
        },
        "OrganizationUnits": {
          "shape": "S22"
        },
        "Regions": {
          "shape": "S22"
        }
      }
    },
    "S22": {
      "type": "list",
      "member": {}
    },
    "S2j": {
      "type": "structure",
      "members": {
        "BackupPlanId": {},
        "BackupPlanArn": {},
        "BackupPlanVersion": {},
        "BackupRuleId": {}
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "AccountId": {},
        "CopyJobId": {},
        "SourceBackupVaultArn": {},
        "SourceRecoveryPointArn": {},
        "DestinationBackupVaultArn": {},
        "DestinationRecoveryPointArn": {},
        "ResourceArn": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "CompletionDate": {
          "type": "timestamp"
        },
        "State": {},
        "StatusMessage": {},
        "BackupSizeInBytes": {
          "type": "long"
        },
        "IamRoleArn": {},
        "CreatedBy": {
          "shape": "S2j"
        },
        "ResourceType": {},
        "ParentJobId": {},
        "IsParent": {
          "type": "boolean"
        },
        "CompositeMemberIdentifier": {},
        "NumberOfChildJobs": {
          "type": "long"
        },
        "ChildJobsInState": {
          "type": "map",
          "key": {},
          "value": {
            "type": "long"
          }
        },
        "ResourceName": {}
      }
    },
    "S2y": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S36": {
      "type": "structure",
      "members": {
        "MoveToColdStorageAt": {
          "type": "timestamp"
        },
        "DeleteAt": {
          "type": "timestamp"
        }
      }
    },
    "S3a": {
      "type": "map",
      "key": {},
      "value": {
        "type": "boolean"
      }
    },
    "S3c": {
      "type": "map",
      "key": {},
      "value": {
        "type": "boolean"
      }
    },
    "S3g": {
      "type": "structure",
      "members": {
        "ReportJobId": {},
        "ReportPlanArn": {},
        "ReportTemplate": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "CompletionTime": {
          "type": "timestamp"
        },
        "Status": {},
        "StatusMessage": {},
        "ReportDestination": {
          "type": "structure",
          "members": {
            "S3BucketName": {},
            "S3Keys": {
              "shape": "S22"
            }
          }
        }
      }
    },
    "S3k": {
      "type": "structure",
      "members": {
        "ReportPlanArn": {},
        "ReportPlanName": {},
        "ReportPlanDescription": {},
        "ReportSetting": {
          "shape": "S21"
        },
        "ReportDeliveryChannel": {
          "shape": "S1z"
        },
        "DeploymentStatus": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "LastAttemptedExecutionTime": {
          "type": "timestamp"
        },
        "LastSuccessfulExecutionTime": {
          "type": "timestamp"
        }
      }
    },
    "S3v": {
      "type": "structure",
      "required": [
        "BackupPlanName",
        "Rules"
      ],
      "members": {
        "BackupPlanName": {},
        "Rules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "RuleName",
              "TargetBackupVaultName"
            ],
            "members": {
              "RuleName": {},
              "TargetBackupVaultName": {},
              "ScheduleExpression": {},
              "StartWindowMinutes": {
                "type": "long"
              },
              "CompletionWindowMinutes": {
                "type": "long"
              },
              "Lifecycle": {
                "shape": "Se"
              },
              "RecoveryPointTags": {
                "shape": "Sf"
              },
              "RuleId": {},
              "CopyActions": {
                "shape": "Si"
              },
              "EnableContinuousBackup": {
                "type": "boolean"
              }
            }
          }
        },
        "AdvancedBackupSettings": {
          "shape": "Sm"
        }
      }
    },
    "S49": {
      "type": "list",
      "member": {}
    },
    "S4f": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "S4w": {
      "type": "structure",
      "members": {
        "BackupPlanArn": {},
        "BackupPlanId": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "DeletionDate": {
          "type": "timestamp"
        },
        "VersionId": {},
        "BackupPlanName": {},
        "CreatorRequestId": {},
        "LastExecutionDate": {
          "type": "timestamp"
        },
        "AdvancedBackupSettings": {
          "shape": "Sm"
        }
      }
    }
  }
}