{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-12-01",
    "endpointPrefix": "verifiedpermissions",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "Amazon Verified Permissions",
    "serviceId": "VerifiedPermissions",
    "signatureVersion": "v4",
    "signingName": "verifiedpermissions",
    "targetPrefix": "VerifiedPermissions",
    "uid": "verifiedpermissions-2021-12-01"
  },
  "operations": {
    "CreateIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "configuration"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyStoreId": {},
          "configuration": {
            "type": "structure",
            "members": {
              "cognitoUserPoolConfiguration": {
                "type": "structure",
                "required": [
                  "userPoolArn"
                ],
                "members": {
                  "userPoolArn": {},
                  "clientIds": {
                    "shape": "S7"
                  }
                }
              }
            },
            "union": true
          },
          "principalEntityType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdDate",
          "identitySourceId",
          "lastUpdatedDate",
          "policyStoreId"
        ],
        "members": {
          "createdDate": {
            "shape": "Sb"
          },
          "identitySourceId": {},
          "lastUpdatedDate": {
            "shape": "Sb"
          },
          "policyStoreId": {}
        }
      },
      "idempotent": true
    },
    "CreatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "definition"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyStoreId": {},
          "definition": {
            "type": "structure",
            "members": {
              "static": {
                "type": "structure",
                "required": [
                  "statement"
                ],
                "members": {
                  "description": {},
                  "statement": {}
                }
              },
              "templateLinked": {
                "type": "structure",
                "required": [
                  "policyTemplateId"
                ],
                "members": {
                  "policyTemplateId": {},
                  "principal": {
                    "shape": "Sk"
                  },
                  "resource": {
                    "shape": "Sk"
                  }
                }
              }
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "policyType",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "policyType": {},
          "principal": {
            "shape": "Sk"
          },
          "resource": {
            "shape": "Sk"
          },
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "CreatePolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "validationSettings"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "validationSettings": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "arn",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "arn": {},
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "CreatePolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "statement"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyStoreId": {},
          "description": {},
          "statement": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "DeleteIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "identitySourceId"
        ],
        "members": {
          "policyStoreId": {},
          "identitySourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "identitySourceId"
        ],
        "members": {
          "policyStoreId": {},
          "identitySourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdDate",
          "details",
          "identitySourceId",
          "lastUpdatedDate",
          "policyStoreId",
          "principalEntityType"
        ],
        "members": {
          "createdDate": {
            "shape": "Sb"
          },
          "details": {
            "type": "structure",
            "members": {
              "clientIds": {
                "shape": "S7"
              },
              "userPoolArn": {},
              "discoveryUrl": {},
              "openIdIssuer": {}
            }
          },
          "identitySourceId": {},
          "lastUpdatedDate": {
            "shape": "Sb"
          },
          "policyStoreId": {},
          "principalEntityType": {}
        }
      }
    },
    "GetPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "policyType",
          "definition",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "policyType": {},
          "principal": {
            "shape": "Sk"
          },
          "resource": {
            "shape": "Sk"
          },
          "definition": {
            "type": "structure",
            "members": {
              "static": {
                "type": "structure",
                "required": [
                  "statement"
                ],
                "members": {
                  "description": {},
                  "statement": {}
                }
              },
              "templateLinked": {
                "type": "structure",
                "required": [
                  "policyTemplateId"
                ],
                "members": {
                  "policyTemplateId": {},
                  "principal": {
                    "shape": "Sk"
                  },
                  "resource": {
                    "shape": "Sk"
                  }
                }
              }
            },
            "union": true
          },
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetPolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "arn",
          "validationSettings",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "arn": {},
          "validationSettings": {
            "shape": "Sr"
          },
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetPolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "statement",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "description": {},
          "statement": {},
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetSchema": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "schema",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "schema": {},
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      }
    },
    "IsAuthorized": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "principal": {
            "shape": "Sk"
          },
          "action": {
            "shape": "S1o"
          },
          "resource": {
            "shape": "Sk"
          },
          "context": {
            "shape": "S1r"
          },
          "entities": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "decision",
          "determiningPolicies",
          "errors"
        ],
        "members": {
          "decision": {},
          "determiningPolicies": {
            "shape": "S27"
          },
          "errors": {
            "shape": "S29"
          }
        }
      }
    },
    "IsAuthorizedWithToken": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "identityToken": {},
          "accessToken": {},
          "action": {
            "shape": "S1o"
          },
          "resource": {
            "shape": "Sk"
          },
          "context": {
            "shape": "S1r"
          },
          "entities": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "decision",
          "determiningPolicies",
          "errors"
        ],
        "members": {
          "decision": {},
          "determiningPolicies": {
            "shape": "S27"
          },
          "errors": {
            "shape": "S29"
          }
        }
      }
    },
    "ListIdentitySources": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "principalEntityType": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "identitySources"
        ],
        "members": {
          "nextToken": {},
          "identitySources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdDate",
                "details",
                "identitySourceId",
                "lastUpdatedDate",
                "policyStoreId",
                "principalEntityType"
              ],
              "members": {
                "createdDate": {
                  "shape": "Sb"
                },
                "details": {
                  "type": "structure",
                  "members": {
                    "clientIds": {
                      "shape": "S7"
                    },
                    "userPoolArn": {},
                    "discoveryUrl": {},
                    "openIdIssuer": {}
                  }
                },
                "identitySourceId": {},
                "lastUpdatedDate": {
                  "shape": "Sb"
                },
                "policyStoreId": {},
                "principalEntityType": {}
              }
            }
          }
        }
      }
    },
    "ListPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "principal": {
                "shape": "S2q"
              },
              "resource": {
                "shape": "S2q"
              },
              "policyType": {},
              "policyTemplateId": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policies"
        ],
        "members": {
          "nextToken": {},
          "policies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "policyStoreId",
                "policyId",
                "policyType",
                "definition",
                "createdDate",
                "lastUpdatedDate"
              ],
              "members": {
                "policyStoreId": {},
                "policyId": {},
                "policyType": {},
                "principal": {
                  "shape": "Sk"
                },
                "resource": {
                  "shape": "Sk"
                },
                "definition": {
                  "type": "structure",
                  "members": {
                    "static": {
                      "type": "structure",
                      "members": {
                        "description": {}
                      }
                    },
                    "templateLinked": {
                      "type": "structure",
                      "required": [
                        "policyTemplateId"
                      ],
                      "members": {
                        "policyTemplateId": {},
                        "principal": {
                          "shape": "Sk"
                        },
                        "resource": {
                          "shape": "Sk"
                        }
                      }
                    }
                  },
                  "union": true
                },
                "createdDate": {
                  "shape": "Sb"
                },
                "lastUpdatedDate": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      }
    },
    "ListPolicyStores": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStores"
        ],
        "members": {
          "nextToken": {},
          "policyStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "policyStoreId",
                "arn",
                "createdDate"
              ],
              "members": {
                "policyStoreId": {},
                "arn": {},
                "createdDate": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      }
    },
    "ListPolicyTemplates": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyTemplates"
        ],
        "members": {
          "nextToken": {},
          "policyTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "policyStoreId",
                "policyTemplateId",
                "createdDate",
                "lastUpdatedDate"
              ],
              "members": {
                "policyStoreId": {},
                "policyTemplateId": {},
                "description": {},
                "createdDate": {
                  "shape": "Sb"
                },
                "lastUpdatedDate": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      }
    },
    "PutSchema": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "definition"
        ],
        "members": {
          "policyStoreId": {},
          "definition": {
            "type": "structure",
            "members": {
              "cedarJson": {}
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "namespaces",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "namespaces": {
            "type": "list",
            "member": {}
          },
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "UpdateIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "identitySourceId",
          "updateConfiguration"
        ],
        "members": {
          "policyStoreId": {},
          "identitySourceId": {},
          "updateConfiguration": {
            "type": "structure",
            "members": {
              "cognitoUserPoolConfiguration": {
                "type": "structure",
                "required": [
                  "userPoolArn"
                ],
                "members": {
                  "userPoolArn": {},
                  "clientIds": {
                    "shape": "S7"
                  }
                }
              }
            },
            "union": true
          },
          "principalEntityType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdDate",
          "identitySourceId",
          "lastUpdatedDate",
          "policyStoreId"
        ],
        "members": {
          "createdDate": {
            "shape": "Sb"
          },
          "identitySourceId": {},
          "lastUpdatedDate": {
            "shape": "Sb"
          },
          "policyStoreId": {}
        }
      },
      "idempotent": true
    },
    "UpdatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "definition"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "definition": {
            "type": "structure",
            "members": {
              "static": {
                "type": "structure",
                "required": [
                  "statement"
                ],
                "members": {
                  "description": {},
                  "statement": {}
                }
              }
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "policyType",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "policyType": {},
          "principal": {
            "shape": "Sk"
          },
          "resource": {
            "shape": "Sk"
          },
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "UpdatePolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "validationSettings"
        ],
        "members": {
          "policyStoreId": {},
          "validationSettings": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "arn",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "arn": {},
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "UpdatePolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "statement"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "description": {},
          "statement": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "createdDate": {
            "shape": "Sb"
          },
          "lastUpdatedDate": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sk": {
      "type": "structure",
      "required": [
        "entityType",
        "entityId"
      ],
      "members": {
        "entityType": {},
        "entityId": {}
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "mode"
      ],
      "members": {
        "mode": {}
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "actionType",
        "actionId"
      ],
      "members": {
        "actionType": {},
        "actionId": {}
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "contextMap": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S1u"
          }
        }
      },
      "union": true
    },
    "S1u": {
      "type": "structure",
      "members": {
        "boolean": {
          "type": "boolean"
        },
        "entityIdentifier": {
          "shape": "Sk"
        },
        "long": {
          "type": "long"
        },
        "string": {},
        "set": {
          "type": "list",
          "member": {
            "shape": "S1u"
          }
        },
        "record": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S1u"
          }
        }
      },
      "union": true
    },
    "S20": {
      "type": "structure",
      "members": {
        "entityList": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "identifier"
            ],
            "members": {
              "identifier": {
                "shape": "Sk"
              },
              "attributes": {
                "type": "map",
                "key": {},
                "value": {
                  "shape": "S1u"
                }
              },
              "parents": {
                "type": "list",
                "member": {
                  "shape": "Sk"
                }
              }
            }
          }
        }
      },
      "union": true
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "policyId"
        ],
        "members": {
          "policyId": {}
        }
      }
    },
    "S29": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "errorDescription"
        ],
        "members": {
          "errorDescription": {}
        }
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "unspecified": {
          "type": "boolean"
        },
        "identifier": {
          "shape": "Sk"
        }
      },
      "union": true
    }
  }
}